/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	RangeDatePicker,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT,
} from "models/dates-and-time/constants";
import {
	formatDefaultDate,
} from "models/dates-and-time/utils/common";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./ChooseDatePeriod.module.css";

class ChoosePeriod extends Component {
	state = {
		fromDate: formatDefaultDate(this.props.fromDate),
		toDate: formatDefaultDate(this.props.toDate),
	};

	close = () => {
		this.props.success();
	};

	lockHandler = () => {
		this.props.success({
			fromDate: formatDefaultDate(this.state.fromDate),
			toDate: formatDefaultDate(this.state.toDate),
		});
	};

	onDatesChange = (dates) => {
		this.setState({
			fromDate: dates.from,
			toDate: dates.to,
		});
	};

	render() {
		const {
			checkDateErrors,
			checkDateWarnings,
			header,
			subheader,
			actionTitle,
		} = this.props;
		const {
			fromDate,
			toDate,
		} = this.state;

		const errors = checkDateErrors(fromDate, toDate);
		const warnings = checkDateWarnings(fromDate, toDate);

		return (
			<ModalBlocker
				{...this.props}
			>
				<ModalWindow width="600">
					<ModalHeader
						rawProps={{
							"data-name": toDataAttribute("Modal window header"),
						}}
						onClose={this.close}
					>
						<span className={styles.header}>
							{header}
						</span>
					</ModalHeader>
					<div className={styles.body}>
						{
							subheader
								? (
									<p className={styles.subheader}>
										{subheader}
									</p>
								)
								: null
						}

						<RangeDatePicker
							value={{
								from: fromDate,
								to: toDate,
							}}
							onValueChange={this.onDatesChange}
							isInvalid={
								errors
									? (
										errors.fromDateHasError
										|| errors.toDateHasError
									)
									: null
							}
							format={UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT}
						/>

						{
							errors
								? errors.descriptions.map((error, key) => {
									return (
										<p
											key={key}
											className={
												classNames(
													styles.tips,
													styles.error,
												)
											}
										>
											{error}
										</p>
									);
								})
								: null
						}
						{
							warnings
								? warnings.map((warning, key) => {
									return (
										<p
											key={key}
											className={styles.tips}
										>
											{warning}
										</p>
									);
								})
								: null
						}
					</div>
					<ModalFooter
						rawProps={{
							"data-name": toDataAttribute("Modal window footer"),
						}}
					>
						<div className={styles["left-auto"]}/>
						<Button
							cx={
								classNames(
									styles.cancel,
									styles.button,
									styles.footer,
								)
							}
							onClick={this.close}
							caption="Cancel"
							color="white"
						/>
						<Button
							caption={actionTitle}
							onClick={this.lockHandler}
							color="grass"
							isDisabled={
								errors
									? (
										errors.fromDateHasError
										|| errors.toDateHasError
									)
									: null
							}
						/>
					</ModalFooter>
				</ModalWindow>
			</ModalBlocker>
		);
	}
}

export default ChoosePeriod;
