/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import {
	CALENDAR_ROW_GROW_TYPE,
} from "./consts";

import styles from "./CalendarRow.module.css";

const CalendarRow = (props) => {
	const {
		marked,
		cxLeft,
		focused,
		cxRight,
		bordered,
		growType,
		dataName,
		leftSize,
		renderLeft,
		renderRight,
		widthLeftClass,
	} = props;

	return (
		<div
			data-name={dataName}
			className={
				classNames(
					props.cx,
					styles["calendar-row"],
					focused
						? styles.focused
						: "",
					marked
						? styles.marked
						: "",
				)
			}
		>
			<div
				className={
					classNames(
						cxLeft,
						styles["left-part"],
						styles[`size-${leftSize}`],
						widthLeftClass || "",
					)
				}
			>
				{renderLeft()}
			</div>
			<div
				className={
					classNames(
						cxRight,
						styles["right-part"],
						bordered
							? styles.bordered
							: "",
						growType === CALENDAR_ROW_GROW_TYPE.GROW_CELL
							? styles["size-auto"]
							: "",
					)
				}
			>
				{renderRight()}
			</div>
		</div>
	);
};

export default CalendarRow;
