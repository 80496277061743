/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Dropdown,
	DropdownContainer,
	FlexRow,
} from "@epam/loveship";
import {
	Fragment,
	useEffect,
	useState,
} from "react";

import {
	round,
} from "utilities/number";

import {
	sortMarkupStatuses,
} from "../../utils";

import styles from "./CommonMarkupDropdown.module.css";

const getStatuseesIds = (markupSummaries, markupStatuses) => {
	return Object.keys(markupSummaries)
		.sort(sortMarkupStatuses(markupStatuses));
};

const InfoMarkupsHoursDropdown = ({
	markupSummaries,
	markupStatuses,
}) => {
	const [
		statuseesIds,
		setStatuseesIds,
	] = useState(
		getStatuseesIds(markupSummaries, markupStatuses),
	);

	useEffect(
		() => {
			setStatuseesIds(getStatuseesIds(markupSummaries, markupStatuses));
		},
		[
			markupStatuses,
			markupSummaries,
		],
	);

	return (
		<Dropdown
			placement="bottom"
			openOnHover={true}
			closeOnMouseLeave="toggler"
			renderTarget={(props) => {
				const {
					ref,
				} = props;

				return (
					<div
						ref={ref}
						className={styles["markups-hours"]}
					>
						{
							statuseesIds.map((statusId, index) => {
								return (
									markupStatuses[statusId]
										? (
											<Fragment key={statusId}>
												<span
													style={{
														color: markupStatuses[statusId].color,
													}}
												>
													{String(round(markupSummaries[statusId]))}
												</span>

												{
													index !== statuseesIds.length - 1
														? "/"
														: null
												}
											</Fragment>
										)
										: null

								);
							})
						}
					</div>
				);
			}}
			renderBody={() => {
				return (
					<DropdownContainer
						width="auto"
						cx={styles["dropdown-container"]}
					>
						<FlexRow
							padding="12"
							size="24"
							vPadding="12"
							cx={styles["dropdown-title"]}
						>
							Status
						</FlexRow>
						<div className={styles["status-container"]}>
							{
								statuseesIds.map((statusId) => {
									return (
										<Fragment key={statusId}>
											<div
												style={{
													color: markupStatuses[statusId].color,
												}}
											>
												{markupStatuses[statusId].name}
											</div>

											<div className={styles["status-hours"]}>
												{`${round(markupSummaries[statusId])}h`}
											</div>
										</Fragment>
									);
								})
							}
						</div>
					</DropdownContainer>
				);
			}}
		/>
	);
};

export default InfoMarkupsHoursDropdown;
