/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	PickerInput,
	RangeDatePicker,
	Text,
} from "@epam/loveship";
import {
	ArrayDataSource,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	getUnixTime,
} from "date-fns";
import {
	Component,
} from "react";

import {
	UUI_PICKER_INPUT_WITH_DASHES_FORMAT,
} from "models/dates-and-time/constants";
import {
	formatDefaultDate,
	formatMarkupStatusDate,
	parseISODate,
} from "models/dates-and-time/utils/common";
import {
	DataPickerNotFoundPlaceholder,
} from "pages/components/data-picker-not-found-placeholder/data-picker-not-found-placeholder";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	MARKUP_ACTION_TYPE,
	MARKUP_APPLIED_LEVEL,
} from "../../consts";

import styles from "./MarkupActionsModal.module.css";

const DEFAULT_STATUS_INDEX = 0;

class MarkupActionsModal extends Component {
	state = {
		statusId: this.props.statuses[DEFAULT_STATUS_INDEX].id,
		from: (
			(
				this.props.filterDates
				&& this.props.filterDates.from
			)
				? formatDefaultDate(this.props.filterDates.from)
				: null
		),
		to: (
			(
				this.props.filterDates
				&& this.props.filterDates.to
			)
				? formatDefaultDate(this.props.filterDates.to)
				: null
		),
	};

	getArrayDataSource = (items) => {
		return new ArrayDataSource({
			items,
		});
	};

	close = () => {
		this.props.abort();
	};

	onAction = () => {
		const {
			action,
			markupLevel,
			worklogDate,
		} = this.props;
		const {
			from,
			to,
			statusId,
		} = this.state;

		this.props.success({
			statusId,
			from: markupLevel === MARKUP_APPLIED_LEVEL.WORKLOG
				? worklogDate
				: from,
			to,
			action,
			markupLevel,
			timesheetDate: worklogDate,
		});
	};

	onDatesChange = (dates) => {
		this.setState({
			from: dates.from,
			to: dates.to,
		});
	};

	filterPeriod = (day) => {
		const {
			filterDates,
		} = this.props;

		const dayValue = getUnixTime(parseISODate(day.toString()));

		return Boolean(
			dayValue >= getUnixTime(parseISODate(filterDates.from))
			&& dayValue <= getUnixTime(parseISODate(filterDates.to)),
		);
	};

	render() {
		const {
			action,
			statuses,
			markupLevel,
			worklogDate,
		} = this.props;
		const {
			from,
			to,
			statusId,
		} = this.state;

		return (
			<ModalBlocker
				{...this.props}
			>
				<ModalWindow width="600">
					<ModalHeader
						rawProps={{
							"data-name": toDataAttribute("Modal window header"),
						}}
						onClose={this.close}
					>
						<span className={styles.header}>
							{
								action === MARKUP_ACTION_TYPE.APPLY
									? "Set worklog status"
									: "Remove worklog status"
							}
						</span>
					</ModalHeader>
					<div className={styles.body}>
						<div className={styles["block-select"]}>
							<Text
								fontWeight="600"
							>
								Select status
							</Text>
							<PickerInput
								dataSource={this.getArrayDataSource(statuses)}
								onValueChange={(statusId) => {
									this.setState({
										statusId,
									});
								}}
								selectionMode="single"
								valueType="statusId"
								getName={(value) => {
									return value.name;
								}}
								value={this.state.statusId}
								minBodyWidth={200}
								size="36"
								placeholder="Please select status"
								renderNotFound={DataPickerNotFoundPlaceholder}
							/>
						</div>

						<div className={styles["block-dates"]}>
							<Text
								fontWeight="600"
							>
								{
									markupLevel !== MARKUP_APPLIED_LEVEL.WORKLOG
										? "Period"
										: "Date"
								}
							</Text>

							{
								markupLevel !== MARKUP_APPLIED_LEVEL.WORKLOG
									? (
										<RangeDatePicker
											value={{
												from,
												to,
											}}
											onValueChange={this.onDatesChange}
											format={UUI_PICKER_INPUT_WITH_DASHES_FORMAT}
											filter={this.filterPeriod}
											disableClear={true}
										/>
									)
									: (
										<Text>
											{formatMarkupStatusDate(worklogDate)}
										</Text>
									)
							}
						</div>

					</div>
					<ModalFooter
						rawProps={{
							"data-name": toDataAttribute("Modal window footer"),
						}}
					>
						<div className={styles["left-auto"]}/>
						<Button
							cx={
								classNames(
									styles.cancel,
									styles.button,
									styles.footer,
								)
							}
							onClick={this.close}
							caption="Cancel"
							color="white"
						/>

						<Button
							caption={
								action === MARKUP_ACTION_TYPE.APPLY
									? "Set status"
									: "Remove"
							}
							onClick={this.onAction}
							color="grass"
							isDisabled={
								statusId === null
								|| (
									worklogDate === undefined
									&& from === null
								)
								|| (
									worklogDate === undefined
									&& to === null
								)
							}
						/>
					</ModalFooter>
				</ModalWindow>
			</ModalBlocker>
		);
	}
}

export default MarkupActionsModal;
