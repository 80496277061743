/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	connect,
} from "react-redux";

import {
	round,
} from "utilities/number";

import CalendarCellWrapper from "./CalendarCellWrapper";
import CalendarSubHeaderCellTooltip from "./CalendarSubHeaderCellTooltip";
import {
	CALENDAR_CELL_TYPE,
} from "./consts";
import {
	getCalendarHeaderHourHints,
} from "./utils";

import styles from "./CalendarSubHeaderCell.module.css";

const CalendarSubHeaderCell = ({
	day,
	size,
	total,
	journalData,
}) => {
	const hints = getCalendarHeaderHourHints(journalData, day);

	return (
		<CalendarCellWrapper
			tooltipPlacement="bottom"
			getTooltip={() => {
				return hints.length
					? {
						text: <CalendarSubHeaderCellTooltip hints={hints}/>,
					}
					: null;
			}}
			size={size}
			calendarCellType={CALENDAR_CELL_TYPE.SUBHEADER}
			today={day.today}
			notWorkDay={!day.workDay}
			isFirstWeekDay={day.isFirstWeekDay}
		>
			<div className={styles["calendar-subheader-cell"]}>
				{
					total[day.date]?.total
						? round(total[day.date].total)
						: "-"
				}
			</div>
		</CalendarCellWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		total: state.journalData.totalHoursByDate,
		calendar: state.journalData.calendar,
		journalData: state.journalData,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSubHeaderCell);
