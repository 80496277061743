/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	DropSpot,
	Text,
} from "@epam/loveship";
import classNames from "classnames";

import fileTypes from "../../fileTypes";

import styles from "./file-uploader.module.css";

const SUPPORTED_FILES_STRING = fileTypes
	.map((ft) => {
		return ft.mime
			? ft.mime
			: `.${ft.ext}`;
	})
	.join(", ");

const checkFilesExtensions = (files) => {
	const areFilesWithWrongExtension = files.find(({
		name,
		type,
	}) => {
		const isCorrectMimeType = fileTypes.find(({
			mime,
		}) => {
			return type === mime;
		});

		if (isCorrectMimeType) {
			return false;
		}

		const fileExtension = (/[^\\]*\.(\w+)$/).exec(name)?.[1];

		const isCorrectFileExtension = fileTypes.find(({
			ext,
		}) => {
			return fileExtension === ext;
		});

		return !isCorrectFileExtension;
	});

	return areFilesWithWrongExtension;
};

const FileUploader = ({
	processFiles,
	onInappropriateFileExtension,
	className,
}) => {
	return (
		<DropSpot
			onUploadFiles={(files) => {
				const areFilesWithWrongExtension = checkFilesExtensions(files);

				if (!areFilesWithWrongExtension) {
					return processFiles(files);
				}

				onInappropriateFileExtension();
			}}
			accept={SUPPORTED_FILES_STRING}
			infoText={(
				<>
					<Text
						color="night500"
						fontSize="14"
						lineHeight="24"
						size="24"
					>
						You can attach maximum 5 files.
					</Text>
					<br/>
					<Text
						color="night500"
						fontSize="12"
						lineHeight="18"
						size="18"
					>
						Please be noticed that due to security reasons
						all files should be scanned for viruses.
						<br/>
						In case of a long queue on its side the upload
						can take up to 10 minutes. Sorry for possible
						delays.
					</Text>
				</>
			)}
			cx={
				classNames(
					styles.fileUploader,
					className,
				)
			}
		/>
	);
};

export default FileUploader;
