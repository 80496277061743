/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import CalendarCellWrapper from "./CalendarCellWrapper";
import {
	CALENDAR_CELL_TYPE,
} from "./consts";

import styles from "./CalendarHeaderCell.module.css";

const CalendarHeaderCell = ({
	day,
	size,
	isLastRounded,
}) => {
	return (
		<CalendarCellWrapper
			size={size}
			calendarCellType={CALENDAR_CELL_TYPE.HEADER}
			today={day.today}
			notWorkDay={!day.workDay}
			isFirstWeekDay={day.isFirstWeekDay}
			reportingLimit={day.reportingLimit}
			billingLimit={day.billingLimit}
			isLastRounded={isLastRounded}
		>
			<div className={styles["calendar-header-cell"]}>
				<span className={styles["day-of-week"]}>
					{day.weekday}
				</span>
				<span className={styles["day-of-month"]}>
					{day.monthday}
				</span>
			</div>
		</CalendarCellWrapper>
	);
};

export default CalendarHeaderCell;
