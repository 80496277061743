/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	MULTIPLE_CHOICE_SET,
} from "./actions";

const initState = {};

export default function multipleChoicesData(
	// It is a correct approach for Redux reducers.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = initState,
	action,
) {
	switch (action.type) {
		case MULTIPLE_CHOICE_SET: {
			return {
				...state,
				...action.payload,
			};
		}

		default: {
			return state;
		}
	}
}
