/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	MULTIPLE_CHOICE_SET,
} from "./actions";
import {
	getArray,
} from "./utils";

const addItemToArray = (item, arrayName) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const array = getArray(getState(), arrayName);
					const obj = {};

					obj[arrayName] = [
						...array,
						item,
					];

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload: {
							...obj,
						},
					});
				});
		},
	};
};

const deleteItemFromArray = (
	item,
	arrayName,
	filterCallback = (item) => {
		return (el) => {
			return el !== item;
		};
	},
) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const array = getArray(getState(), arrayName);
					const obj = {};

					obj[arrayName] = array.filter(filterCallback(item));

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload: {
							...obj,
						},
					});
				});
		},
	};
};

const addAllItemsToArray = (arrayName, getItemsCallback) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const obj = {};

					obj[arrayName] = getItemsCallback(getState);

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload: {
							...obj,
						},
					});
				});
		},
	};
};

const removeAllItemsFromArray = (arrayName) => {
	return {
		promise(dispatch) {
			return Promise.resolve()
				.then(() => {
					const obj = {};

					obj[arrayName] = [];

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload: {
							...obj,
						},
					});
				});
		},
	};
};

const updateWithFilteredValues = (
	filteredItems,
	arrayName,
	filterCallback = (filteredItem) => {
		return (el) => {
			return filteredItem.some((itm) => {
				return el === itm;
			});
		};
	},
) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const array = getArray(getState(), arrayName);
					const obj = {};

					obj[arrayName] = array.filter(filterCallback(filteredItems));

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload: {
							...obj,
						},
					});
				});
		},
	};
};

export {
	addItemToArray,
	deleteItemFromArray,
	addAllItemsToArray,
	removeAllItemsFromArray,
	updateWithFilteredValues,
};
