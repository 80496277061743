/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	TooltipWithHeader,
} from "pages/components/tooltip-with-header/tooltip-with-header";

import {
	CALENDAR_CELL_TYPE,
} from "./consts";

import styles from "./CalendarCellWrapper.module.css";

class CalendarCellWrapper extends Component {
	state = {
		hover: false,
	};

	onMouseEnter = (e) => {
		const {
			onMouseEnter,
		} = this.props;

		if (onMouseEnter) {
			onMouseEnter(e);
		}
	};

	onMouseLeave = (e) => {
		const {
			onMouseLeave,
		} = this.props;

		this.setState({
			hover: false,
		});

		if (onMouseLeave) {
			onMouseLeave(e);
		}
	};

	onMouseMove = (e) => {
		const {
			onMouseMove,
		} = this.props;

		if (!this.state.hover) {
			this.setState({
				hover: true,
			});
		}

		if (onMouseMove) {
			onMouseMove(e);
		}
	};

	onClick = (e) => {
		const {
			onClick,
		} = this.props;

		if (onClick) {
			onClick(e);
		}
	};

	onDoubleClick = (e) => {
		const {
			onDoubleClick,
		} = this.props;

		if (onDoubleClick) {
			onDoubleClick(e);
		}
	};

	onDragStart = (e) => {
		const {
			onDragStart,
		} = this.props;

		if (onDragStart) {
			onDragStart(e);
		}
	};

	onDrop = (e) => {
		const {
			onDrop,
		} = this.props;

		if (onDrop) {
			onDrop(e);
		}
	};

	/**
	 * @returns {import("react").ComponentProps<typeof InfoTooltip>}
	 */
	getTooltipParams = () => {
		const {
			getTooltip,
			tooltipPlacement = "top",
		} = this.props;

		const {
			hover,
		} = this.state;

		const dynamicParams = (
			hover
				? getTooltip?.()
				: undefined
		);

		return {
			...dynamicParams,
			placement: tooltipPlacement,
		};
	};

	render() {
		const {
			size,
			today,
			changed,
			children,
			selected,
			onMouseUp,
			notWorkDay,
			selectedEnd,
			onMouseDown,
			selectedInit,
			billingLimit,
			isLastRounded,
			nonWorkingDay,
			selectedStart,
			isFirstWeekDay,
			reportingLimit,
			calendarCellType,
			vacationType = "",
			innerRef,
			getTooltip,
		} = this.props;

		const isNonWorkingDayValid = (
			nonWorkingDay
			&& calendarCellType !== CALENDAR_CELL_TYPE.LOCKED
		);

		const content = (
			<div>
				{
					vacationType === CALENDAR_CELL_TYPE.MIXED_VACATION
						? <div className={styles["mixed-vacation-separator"]}/>
						: null
				}
				{children}
			</div>
		);

		return (
			<div
				ref={innerRef}
				className={
					classNames(
						styles.calendarCell,
						styles[`size-${size}`],
						{
							[styles.nonWorkingDay]: isNonWorkingDayValid,
						},
						styles[String(vacationType)],
						styles[String(calendarCellType)],
						billingLimit
							? styles["billing-limit"]
							: reportingLimit
								? styles["reporting-limit"]
								: today
									? styles.today
									: "",
						notWorkDay
							? styles["not-work-day"]
							: "",
						isFirstWeekDay
							? styles["first-weekday"]
							: "",
						changed
							? styles.changed
							: "",
						selected
							? styles.selected
							: "",
						selectedStart
							? styles["start-select"]
							: "",
						selectedEnd
							? styles["end-select"]
							: "",
						isLastRounded
							? styles["is-last-rounded"]
							: "",
					)
				}
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				onMouseMove={this.onMouseMove}
				onClick={this.onClick}
				onDoubleClick={this.onDoubleClick}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onDragStart={this.onDragStart}
				onDrop={this.onDrop}
			>
				{
					getTooltip
						? (
							<TooltipWithHeader
								{...this.getTooltipParams()}
							>
								{content}
							</TooltipWithHeader>
						)
						: content
				}
				{
					selectedInit
						? <div className={styles["selection-init"]}/>
						: null
				}
			</div>
		);
	}
}

export default CalendarCellWrapper;
