/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as FileAttachmentIcon,
} from "@epam/assets/icons/common/file-attachment-12.svg";
import {
	Dropdown,
	DropdownContainer,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Fragment,
	useEffect,
	useRef,
	useState,
} from "react";

import {
	ReactComponent as OvertimeIndicatorIcon,
} from "icons/overtime-10x15.svg";
import {
	ReactComponent as MarkupPlusIcon,
} from "icons/show-more-markup-statuses-5x6.svg";

import {
	OVERTIME_INDICATOR_ICONS,
	OVERTIME_MODE,
} from "../../consts";
import HasErrorCellIcon from "../../journal/table-activity/table-activity-cell/has-error-cell-icon/HasErrorCellIcon";

import styles from "./IconsCalendarCellWrapper.module.css";

const MARKUP_MARKER_WIDTH = 7;
const SINGLE_OVERTIME_WIDTH = 14;
const MULTI_OVERTIME_WIDTH = 18;

const IconsCalendarCellWrapper = ({
	children,
	isMarkupModeActive,
	markupStatuses,
	hasErrors,
	hasHasAttachments,
	hasOvertime,
	overtimeIndicator,
	overtimeMode,
	statusConfig,
}) => {
	const [
		visibleStatuses,
		setVisibleStatuses,
	] = useState([]);
	const [
		isShowMoreStatusIcon,
		setIsShowMoreStatusIcon,
	] = useState(false);

	const isMultiOvertime = overtimeMode === OVERTIME_MODE.MULTI;

	const cell = useRef(null);

	useEffect(
		() => {
			if (
				cell?.current?.clientWidth
				&& markupStatuses?.length
			) {
				const overtimeIconWidth = (
					hasOvertime
						? isMultiOvertime
							? MULTI_OVERTIME_WIDTH
							: SINGLE_OVERTIME_WIDTH
						: 0
				);

				const widthMarkupWrapper = (
					cell.current.clientWidth
					- 1
					- overtimeIconWidth
				);

				let count = Math.round(widthMarkupWrapper / MARKUP_MARKER_WIDTH);

				if (count < markupStatuses.length) {
					setIsShowMoreStatusIcon(true);

					count -= 1;
				} else {
					setIsShowMoreStatusIcon(false);
				}

				setVisibleStatuses(markupStatuses.slice(0, count));
			}
		},
		[
			cell,
			markupStatuses,
			hasOvertime,
			overtimeMode,
			isMultiOvertime,
		],
	);

	/** @type {import("react").ComponentProps<typeof Dropdown>["renderTarget"]} */
	const renderMarkupIcons = (props) => {
		const {
			ref,
		} = props;

		return (
			<div
				ref={ref}
				data-name="markup-journal-icon"
				className={styles["markup-wrapper"]}
			>
				{
					visibleStatuses.map((status) => {
						return (
							<div
								key={status.id}
								className={styles.markup}
								style={{
									background: statusConfig[status.id]?.color,
								}}
							/>
						);
					})
				}
				{
					isShowMoreStatusIcon
						? <MarkupPlusIcon/>
						: null
				}
			</div>
		);
	};

	return (
		<div
			ref={cell}
			className={styles["icons-cell-wrapper"]}
		>
			{
				(
					isMarkupModeActive
					&& markupStatuses?.length
				)
					? (
						<Dropdown
							placement="bottom"
							// TODO: consider using `Tooltip` instead.
							openOnHover={true}
							closeOnMouseLeave="toggler"
							renderTarget={renderMarkupIcons}
							renderBody={() => {
								return (
									<DropdownContainer
										width="auto"
										cx={styles["dropdown-container"]}
									>
										<div className={styles["status-container"]}>
											{
												markupStatuses.map((status) => {
													return (
														<Fragment key={statusConfig[status.id].id}>
															<div
																style={{
																	color: statusConfig[status.id].color,
																}}
															>
																{statusConfig[status.id].name}
															</div>

															<div className={styles["status-changed"]}>
																{status.changed}
															</div>
														</Fragment>
													);
												})
											}
										</div>
									</DropdownContainer>
								);
							}}
						/>
					)
					: null
			}

			{
				hasOvertime
					? (
						<OvertimeIndicatorIcon
							className={
								classNames(
									styles["overtime-indicator"],
									(
										overtimeIndicator === OVERTIME_INDICATOR_ICONS.ALL_APPROVE
										|| overtimeIndicator === OVERTIME_INDICATOR_ICONS.PART_APPROVE
									)
										? styles["overtime-indicator-approve"]
										: "",
								)
							}
						/>
					)
					: null
			}
			{
				(
					hasOvertime
					&& overtimeMode === OVERTIME_MODE.MULTI
				)
					? (
						<OvertimeIndicatorIcon
							className={
								classNames(
									styles["second-overtime-indicator"],
									overtimeIndicator === OVERTIME_INDICATOR_ICONS.ALL_APPROVE
										? styles["overtime-indicator-approve"]
										: "",
								)
							}
						/>
					)
					: null
			}
			{children}

			{
				hasErrors
					? <HasErrorCellIcon/>
					: null
			}

			{
				hasHasAttachments
					? <FileAttachmentIcon className={styles["attachment-indicator"]}/>
					: null
			}
		</div>
	);
};

export default IconsCalendarCellWrapper;
