/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as iconCalendar,
} from "@epam/assets/icons/common/action-calendar-18.svg";
import {
	ReactComponent as iconLeft,
} from "@epam/assets/icons/common/navigation-chevron-left-24.svg";
import {
	ReactComponent as iconRight,
} from "@epam/assets/icons/common/navigation-chevron-right-24.svg";
import {
	Button,
	Dropdown,
	DropdownContainer,
	FlexRow,
	TextInput,
} from "@epam/loveship";
import {
	format,
} from "date-fns";
import {
	useState,
} from "react";

import {
	FULL_YEAR_AND_FULL_MONTH_FORMAT,
} from "models/dates-and-time/constants";
import {
	addDateMonths,
	formatDefaultDate,
	getDateYear,
	getMonthNumber,
	parseISODate,
	setDateMonth,
	setDateYear,
	startDateOfMonth,
	subDateMonths,
} from "models/dates-and-time/utils/common";

import MonthSelection from "./components/month-selection/MonthSelection";
import YearSelection from "./components/year-selection/YearSelection";
import {
	DEFAULT_START_YEAR,
	DISPLAYED_YEARS_NUMBER,
} from "./constants";

import styles from "./MonthYearPicker.module.css";

const calcStartDisplayedYear = (year, startYear = DEFAULT_START_YEAR) => {
	const calcYear = (
		(
			year
			&& Number.isInteger(year)
			&& year >= startYear
		)
			? year
			: getDateYear(new Date())
	);

	const stepsCount = Math.floor(
		(calcYear - startYear) / DISPLAYED_YEARS_NUMBER,
	);

	return startYear + (stepsCount * DISPLAYED_YEARS_NUMBER);
};

const DROPDOWN_VIEW = {
	MONTH: "MONTH",
	YEAR: "YEAR",
};

const MonthYearPicker = ({
	startYear = DEFAULT_START_YEAR,
	date,
	setDate,
}) => {
	const dt = date || new Date();

	const [
		isOpen,
		setIsOpen,
	] = useState(false);
	const [
		startDisplayedYear,
		setStartDisplayedYear,
	] = useState(
		calcStartDisplayedYear(getDateYear(dt)),
	);
	const [
		dropdownView,
		setDropdownView,
	] = useState(DROPDOWN_VIEW.MONTH);
	const [
		year,
		setYear,
	] = useState(getDateYear(dt));

	const setPrevMonth = () => {
		if (date) {
			setDate(
				formatDefaultDate(
					startDateOfMonth(
						subDateMonths({
							date: dt,
							amount: 1,
						}),
					),
				),
			);
		}
	};

	const setNextMonth = () => {
		if (date) {
			setDate(
				formatDefaultDate(
					startDateOfMonth(
						addDateMonths({
							date: dt,
							amount: 1,
						}),
					),
				),
			);
		}
	};

	const setMonth = (month) => {
		setDate(
			formatDefaultDate(
				startDateOfMonth(
					setDateYear({
						date: setDateMonth({
							date: dt,
							value: month,
						}),
						value: year,
					}),
				),
			),
		);

		setIsOpen(false);
	};

	const setYearView = () => {
		setDropdownView(DROPDOWN_VIEW.YEAR);
	};

	return (
		<FlexRow
			background="white"
			cx={styles["controls-container"]}
			spacing={null}
		>
			<Button
				cx={styles["prev-month"]}
				icon={iconLeft}
				fill="light"
				size="36"
				color="night700"
				onClick={setPrevMonth}
				isDisabled={
					getMonthNumber(dt) === 0
						? getDateYear(dt) === startYear
						: null
				}
			/>
			<Dropdown
				value={isOpen}
				onValueChange={(val) => {
					if (!val) {
						setYear(getDateYear(dt));
					}

					setIsOpen(val);
				}}
				renderBody={(props) => {
					return (
						<DropdownContainer
							{...props}
							width="340px"
						>
							{
								dropdownView === DROPDOWN_VIEW.MONTH
									? (
										<MonthSelection
											date={date}
											year={year}
											setYear={setYear}
											onValueChange={setMonth}
											onYearClick={setYearView}
											startYear={startYear}
										/>
									)
									: null
							}
							{
								dropdownView === DROPDOWN_VIEW.YEAR
									? (
										<YearSelection
											startDisplayedYear={startDisplayedYear}
											year={year}
											setYear={(yr) => {
												setDropdownView(DROPDOWN_VIEW.MONTH);

												setYear(yr);
											}}
											setStartDisplayedYear={setStartDisplayedYear}
											startYear={startYear}
										/>
									)
									: null
							}
						</DropdownContainer>
					);
				}}
				renderTarget={(props) => {
					return (
						<TextInput
							{...props}
							value={
								date
									? format(parseISODate(dt), FULL_YEAR_AND_FULL_MONTH_FORMAT)
									: ""
							}
							mode="inline"
							icon={iconCalendar}
							isDropdown={false}
						/>
					);
				}}
			/>
			<Button
				cx={styles["next-month"]}
				icon={iconRight}
				fill="light"
				size="36"
				color="night700"
				onClick={setNextMonth}
			/>
		</FlexRow>
	);
};

export default MonthYearPicker;
