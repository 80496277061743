/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./Confirm.module.css";

class Confirm extends Component {
	close = () => {
		this.props.success();
	};

	submit = () => {
		this.props.success(true);
	};

	render() {
		const {
			title,
			message,
			corfirmTitle,
			cancelTitle,
			showCancel = false,
		} = this.props;

		return (
			<ModalBlocker
				{...this.props}
			>
				<ModalWindow width="600">
					<ModalHeader
						title={title}
						rawProps={{
							"data-name": toDataAttribute("Modal window header"),
						}}
						onClose={this.close}
					/>
					<div className={styles.body}>
						<FlexRow
							vPadding="24"
							padding="24"
							size="24"
						>
							{message}
						</FlexRow>
					</div>
					<ModalFooter
						borderTop={true}
						rawProps={{
							"data-name": toDataAttribute("Modal window footer"),
						}}
					>
						<div className={styles["left-auto"]}/>
						{
							showCancel
								? (
									<Button
										cx={
											classNames(
												styles.cancel,
												styles.button,
												styles.footer,
											)
										}
										onClick={this.close}
										caption={cancelTitle}
										color="white"
									/>
								)
								: null
						}
						<Button
							caption={corfirmTitle}
							onClick={this.submit}
							color="grass"
						/>
					</ModalFooter>
				</ModalWindow>
			</ModalBlocker>
		);
	}
}

export default Confirm;
