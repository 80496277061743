/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import "./Button.css";

const Button = ({
	clickCallback,
	name,
	type,
	disabled,
	children,
	dataTooltip,
}) => {
	return (
		<button
			type="button"
			className={
				classNames(
					type,
					"button",
					{
						disabled,
					},
				)
			}
			data-name={name}
			onClick={clickCallback}
			disabled={disabled}
			data-tooltip={dataTooltip}
		>
			{name}
			{children}
		</button>
	);
};

export default Button;
