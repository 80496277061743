/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	Link,
} from "react-router-dom";

import "./AccessDenied.css";

/**
 * @typedef {Object} AccessDeniedProps
 * @property {string} [className]
 */

/** @type {import("react").FC<AccessDeniedProps>} */
const AccessDenied = ({
	className,
}) => {
	return (
		<div
			className={
				classNames(
					"access-denied",
					className,
				)
			}
		>
			<div className="image"/>
			<span className="main-desc">
				You have no permission
			</span>
			<span className="additional-desc">
				Sorry but it seems like we cant&apos;t let you pass
			</span>
			<span className="additional-desc">
				But you always can go to
				{" "}
				<Link
					to="/"
					className="link"
				>
					the home page
					{" "}
				</Link>
			</span>
		</div>
	);
};

export default AccessDenied;
