/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	IconContainer,
} from "@epam/loveship";
import classNames from "classnames";

import {
	ReactComponent as ArrowDownIcon,
} from "icons/arrow-down-24x8.svg";
import {
	ReactComponent as ArrowRightIcon,
} from "icons/arrow-right-24.svg";

import styles from "./OpenerIndicator.module.css";

const OpenerIndicator = (props) => {
	const {
		open,
		children,
	} = props;

	return (
		<div className={styles["opener-indicator"]}>
			<div
				className={
					classNames(
						styles.icon,
						props.cx,
					)
				}
			>
				{
					open
						? <IconContainer icon={ArrowDownIcon}/>
						: <IconContainer icon={ArrowRightIcon}/>
				}
			</div>
			<div className={styles["info-overlong"]}>
				{children}
			</div>
		</div>
	);
};

export default OpenerIndicator;
