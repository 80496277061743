/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	format,
} from "date-fns";
import toLower from "lodash/toLower";

import {
	DAY_WITH_LEADING_ZERO,
	SHORT_WEEK_DAY_NAME_FORMAT,
} from "models/dates-and-time/constants";
import {
	isDateEqual,
	parseISODate,
	startDateOfDay,
} from "models/dates-and-time/utils/common";
import {
	round,
} from "utilities/number";

import {
	CALENDAR_CELL_TYPE,
	CALENDAR_ROW_GROW_TYPE,
	FIRST_LIMIT_CELLS_COUNT,
	PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS,
	SECOND_LIMIT_CELLS_COUNT,
} from "./consts";

const getDateParams = (date) => {
	return {
		today: isDateEqual({
			date: startDateOfDay(new Date()),
			dateToCompare: date,
		}),
		isFirstWeekDay: toLower(
			format(
				parseISODate(date),
				SHORT_WEEK_DAY_NAME_FORMAT,
			),
		) === "mon",
		weekday: format(parseISODate(date), SHORT_WEEK_DAY_NAME_FORMAT),
		monthday: format(parseISODate(date), DAY_WITH_LEADING_ZERO),
	};
};

const getCellSize = (growType, calendarLength) => {
	let res = "small";

	if (growType === CALENDAR_ROW_GROW_TYPE.GROW_CELL) {
		res = "auto";
	} else if (calendarLength <= FIRST_LIMIT_CELLS_COUNT) {
		res = "large";
	} else if (calendarLength <= SECOND_LIMIT_CELLS_COUNT) {
		res = "medium";
	}

	return res;
};

const getLeftPartSize = (growType, calendarLength) => {
	let res = "auto";

	if (
		growType === CALENDAR_ROW_GROW_TYPE.GROW_LEFT_PART
		&& calendarLength <= SECOND_LIMIT_CELLS_COUNT
	) {
		res = "fixed-900";
	}

	return res;
};

const getCalendarHeaderHourHints = (journalData, day) => {
	const hints = [];
	let isAutofillExist = false;
	const {
		vacations = {},
		allProjectsTotalHours = {},
		allAutofillTotalHours = {},
		allNonProjectsTotalHours = {},
	} = journalData;

	const projectHours = allProjectsTotalHours[day.date];
	const autofillHours = allAutofillTotalHours[day.date];
	const npaHours = allNonProjectsTotalHours[day.date];
	const vacHours = vacations?.dailyTotalHours[day.date];

	if (autofillHours) {
		isAutofillExist = true;
	}

	if (autofillHours) {
		hints.push({
			day: day.date,
			text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.AUTOFILL,
			hours: autofillHours.total,
			className: "autofill",
		});
	}

	if (projectHours) {
		const hoursDiff = (
			projectHours.totalOvt
				? round(projectHours.total - projectHours.totalOvt)
				: round(projectHours.total)
		);

		if (hoursDiff) {
			hints.push({
				day: day.date,
				text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.PROJECT_ACTIVITIES,
				hours: hoursDiff,
			});
		}

		if (projectHours.totalOvt) {
			hints.push({
				day: day.date,
				text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.OVERTIME_ACTIVITIES,
				hours: projectHours.totalOvt,
			});
		}
	}

	if (vacHours) {
		hints.push({
			day: day.date,
			text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.LEAVE,
			hours: vacHours.total,
		});
	}

	if (npaHours) {
		const hoursDiff = (
			npaHours.totalOvt
				? round(npaHours.total - npaHours.totalOvt)
				: round(npaHours.total)
		);

		if (hoursDiff) {
			hints.push({
				day: day.date,
				text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.NON_PROJECT_ACTIVITIES,
				hours: hoursDiff,
			});
		}

		if (npaHours.totalOvt) {
			const ovtHint = hints.find((hint) => {
				return (
					hint.text === PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.OVERTIME_ACTIVITIES
				);
			});

			if (ovtHint) {
				ovtHint.hours += npaHours.totalOvt;

				hints.forEach((hint) => {
					if (hint.text === PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.OVERTIME_ACTIVITIES) {
						hint.hours = ovtHint.hours;
					}
				});
			}

			if (!ovtHint) {
				hints.push({
					day: day.date,
					text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.OVERTIME_ACTIVITIES,
					hours: npaHours.totalOvt,
				});
			}
		}
	}

	if (
		isAutofillExist
		&& hints.length > 1
	) {
		hints.splice(
			1,
			0,
			{
				day: day.date,
				text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.REPORTED,
				className: "reported",
			},
		);
	} else {
		hints.unshift({
			day: day.date,
			text: PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.REPORTED,
			className: "reported",
		});
	}

	const NO_REPORTED_HOURS = 2;

	if (
		isAutofillExist
		&& hints.length === NO_REPORTED_HOURS
	) {
		hints.shift();
	}

	if (
		hints.length === 1
		&& hints[0].text === PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS.REPORTED
	) {
		hints.pop();
	}

	return hints;
};

const getVacationCellTape = (date, vacTypes) => {
	const hasActualVac = vacTypes.some(({
		vacations,
	}) => {
		return vacations.find((vac) => {
			return vac.date === date;
		});
	});
	const hasPlannedVac = vacTypes.some(({
		plannedVacations,
	}) => {
		return plannedVacations.find((vac) => {
			return vac.date === date;
		});
	});

	if (
		hasActualVac
		&& hasPlannedVac
	) {
		return CALENDAR_CELL_TYPE.MIXED_VACATION;
	}

	if (hasPlannedVac) {
		return CALENDAR_CELL_TYPE.PLANNED_VACATION;
	}

	return CALENDAR_CELL_TYPE.ACTUAL_VACATION;
};

export {
	getDateParams,
	getCellSize,
	getLeftPartSize,
	getCalendarHeaderHourHints,
	getVacationCellTape,
};
