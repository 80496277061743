/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as iconLeft,
} from "@epam/assets/icons/common/navigation-chevron-left-24.svg";
import {
	ReactComponent as iconRight,
} from "@epam/assets/icons/common/navigation-chevron-right-24.svg";
import {
	Button,
	FlexCell,
	FlexRow,
	FlexSpacer,
	LinkButton,
	Text,
} from "@epam/loveship";
import {
	arrayToMatrix,
} from "@epam/uui-core";
import classNames from "classnames";

import {
	getDateYear,
} from "models/dates-and-time/utils/common";

import {
	DISPLAYED_YEARS_NUMBER,
	YEAR_ROW_LENGTH,
} from "../../constants";

import styles from "./YearSelection.module.css";

const YearSelection = ({
	startDisplayedYear,
	year,
	setYear,
	setStartDisplayedYear,
	startYear,
}) => {
	const setPrevStartYear = () => {
		if (startDisplayedYear > startYear) {
			setStartDisplayedYear(startDisplayedYear - DISPLAYED_YEARS_NUMBER);
		}
	};

	const setNextStartYear = () => {
		setStartDisplayedYear(startDisplayedYear + DISPLAYED_YEARS_NUMBER);
	};

	return (
		<>
			<FlexRow
				padding="24"
				vPadding="24"
			>
				<Button
					icon={iconLeft}
					fill="light"
					size="36"
					color="night700"
					onClick={setPrevStartYear}
					isDisabled={startDisplayedYear <= startYear}
				/>
				<FlexSpacer/>
				<Text>
					Choose year
				</Text>
				<FlexSpacer/>
				<Button
					icon={iconRight}
					fill="light"
					size="36"
					color="night700"
					onClick={setNextStartYear}
				/>
			</FlexRow>

			{
				arrayToMatrix(
					[
						...Array(DISPLAYED_YEARS_NUMBER),
					],
					YEAR_ROW_LENGTH,
				)
					.map((row, rowIndex) => {
						return (
							<FlexRow
								padding="24"
								key={rowIndex}
								vPadding="12"
							>
								{
									row.map((monthName, index) => {
										const itemYear = (
											startDisplayedYear
											+ index
											+ (rowIndex * YEAR_ROW_LENGTH)
										);

										return (
											<FlexCell
												key={index}
												textAlign="center"
												shrink={0}
												width={70}
											>
												{
													itemYear === year
														? (
															<Button
																cx={styles.yearName}
																caption={itemYear}
																shape="round"
																onClick={() => {
																	setYear(itemYear);
																}}
															/>
														)
														: (
															<LinkButton
																onClick={() => {
																	setYear(itemYear);
																}}
																caption={itemYear}
																color="night600"
																cx={
																	classNames(
																		styles.yearName,
																		{
																			[styles.fontWeightRegular]: itemYear !== getDateYear(new Date()),
																		},
																	)
																}
															/>
														)
												}
											</FlexCell>
										);
									})
								}
							</FlexRow>
						);
					})
			}
		</>
	);
};

export default YearSelection;
