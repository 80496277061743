/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	format,
} from "date-fns";

import {
	FULL_YEAR_AND_FULL_MONTH_FORMAT,
	FULL_YEAR_FORMAT,
} from "models/dates-and-time/constants";
import {
	getDateYear,
	parseISODate,
} from "models/dates-and-time/utils/common";

import {
	PERIODS,
} from "../../../environment/journal_environment";

const DATE_FROM_YEAR_SHIFT = 5;
const DATE_TO_YEAR_SHIFT = 6;

const VIEW_MODES = {
	MONTH: {
		name: "MONTH",
		num: 1,
		units: "months",
		getLabel(date) {
			return format(parseISODate(date), FULL_YEAR_AND_FULL_MONTH_FORMAT);
		},
	},
	YEAR: {
		name: "YEAR",
		num: 1,
		units: "years",
		getLabel(date) {
			return format(parseISODate(date), FULL_YEAR_FORMAT);
		},
	},
	TWELVE_YEAR: {
		name: "TWELVE_YEAR",
		num: 12,
		units: "years",
		getLabel(date) {
			const year = getDateYear(date);

			return `${year - DATE_FROM_YEAR_SHIFT} - ${year + DATE_TO_YEAR_SHIFT}`;
		},
	},
};

const getViewMode = (period) => {
	switch (period) {
		case PERIODS.MONTH: {
			return VIEW_MODES.YEAR.name;
		}

		case PERIODS.TWO_WEEKS:
		case PERIODS.WEEK:
		case PERIODS.DAY: {
			return VIEW_MODES.MONTH.name;
		}

		default: {
			return null;
		}
	}
};

const findViewMode = (modeName) => {
	return (
		Object.values(VIEW_MODES)
			.find(({
				name,
			}) => {
				return name === modeName;
			})
	);
};

const outViewMode = (mode) => {
	switch (mode) {
		case VIEW_MODES.MONTH.name: {
			return VIEW_MODES.YEAR.name;
		}

		case VIEW_MODES.YEAR.name: {
			return VIEW_MODES.TWELVE_YEAR.name;
		}

		case VIEW_MODES.TWELVE_YEAR.name:
		default: {
			return mode;
		}
	}
};

const inViewMode = (mode) => {
	switch (mode) {
		case VIEW_MODES.TWELVE_YEAR.name: {
			return VIEW_MODES.YEAR.name;
		}

		case VIEW_MODES.YEAR.name: {
			return VIEW_MODES.MONTH.name;
		}

		case VIEW_MODES.MONTH.name:
		default: {
			return mode;
		}
	}
};

export {
	getViewMode,
	findViewMode,
	outViewMode,
	inViewMode,
};
export default VIEW_MODES;
