/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const CALENDAR_CELL_TYPE = {
	BASE: "",
	HEADER: "header",
	SUBHEADER: "sub-header",
	PROJECT_ROW: "project-row",
	LOCKED: "locked",
	NO_WORK_DAY: "no-work-day",
	OVERTIME_SUBMITTED: "overtime-submitted",
	OVERTIME_APPROVED: "overtime-approved",
	MARKED: "marked",
	PREDICTED: "predicted",
	TRACKED: "tracked",

	DASHBOARD_BILLING_LOCK: "billing-lock",
	DASHBOARD_REPORTING_LOCK: "reporting-lock",

	MIXED_VACATION: "mixed-vacation",
	ACTUAL_VACATION: "actual-vacation",
	PLANNED_VACATION: "planned-vacation",
};

const CALENDAR_ROW_GROW_TYPE = {
	GROW_LEFT_PART: "GROW_LEFT_PART",
	GROW_CELL: "GROW_CELL",
};

const FIRST_LIMIT_CELLS_COUNT = 10;
const SECOND_LIMIT_CELLS_COUNT = 20;

const PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS = {
	AUTOFILL: "Proposed prefill",
	REPORTED: "Reported",
	PROJECT_ACTIVITIES: "Project activities",
	OVERTIME_ACTIVITIES: "Overtime activities",
	NON_PROJECT_ACTIVITIES: "Non-project activities",
	LEAVE: "Leave",
};

export {
	CALENDAR_CELL_TYPE,
	CALENDAR_ROW_GROW_TYPE,
	FIRST_LIMIT_CELLS_COUNT,
	SECOND_LIMIT_CELLS_COUNT,
	PROJECT_TOTAL_HOUR_TOOLTIP_TEXTS,
};
