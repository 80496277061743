/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type FC,
	type ReactNode,
} from "react";

import {
	type WithChildren,
} from "types/common";

import styles from "./filter-control-row.module.css";

interface FilterControlRowProps extends WithChildren {
	title: ReactNode;
	titleClassName?: string;
}

const FilterControlRow: FC<FilterControlRowProps> = ({
	title,
	titleClassName,
	children,
}) => {
	return (
		<div
			className={styles.filterControlRow}
		>
			<h3
				className={
					classNames(
						styles.title,
						titleClassName,
					)
				}
			>
				{title}
			</h3>

			{children}
		</div>
	);
};

export {
	FilterControlRow,
};
