/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	daysInWeek,
} from "date-fns/constants";

import {
	addDateDays,
	endDateOfISOWeek,
	endDateOfMonth,
	formatDefaultDate,
	startDateOfISOWeek,
	startDateOfMonth,
	subDateWeeks,
} from "models/dates-and-time/utils/common";

import {
	PERIODS,
} from "../../../../environment/journal_environment";

const getDatesForCalendar = (date) => {
	return {
		startOfViewDate: startDateOfISOWeek(startDateOfMonth(date)),
		endOfViewDate: endDateOfISOWeek(endDateOfMonth(date)),
	};
};

const getStartAndEndDates = (date, period) => {
	switch (period) {
		case PERIODS.DAY: {
			return {
				startDate: date || new Date(),
				endDate: date || new Date(),
			};
		}

		case PERIODS.WEEK: {
			return {
				startDate: startDateOfISOWeek(date),
				endDate: endDateOfISOWeek(date),
			};
		}

		case PERIODS.TWO_WEEKS: {
			return {
				startDate: startDateOfISOWeek(subDateWeeks({
					date,
					amount: 1,
				})),
				endDate: endDateOfISOWeek(date),
			};
		}

		default: {
			return null;
		}
	}
};

const getDays = (num, initDate) => {
	const days = [];
	let weekInd = -1;
	let sumDays = initDate;

	for (let i = 0; i < num; i++) {
		if (i % daysInWeek === 0) {
			weekInd++;

			days.push([]);
		}

		days[weekInd] = [
			...days[weekInd],
			formatDefaultDate(sumDays),
		];

		sumDays = addDateDays({
			date: sumDays,
			amount: 1,
		});
	}

	return days;
};

export {
	getStartAndEndDates,
	getDays,
	getDatesForCalendar,
};
