/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import styles from "./CalendarSubHeaderCellTooltip.module.css";

const HINTS_FALLBACK = [];

const CalendarSubHeaderCellTooltip = ({
	hints = HINTS_FALLBACK,
}) => {
	return (
		<>
			{
				hints.map((hint, index) => {
					return (
						<div
							key={`${hint.day}-${index}`}
							className={
								classNames(
									styles.tableRow,
									styles[hint.className],
								)
							}
						>
							<div className={styles.tableColumn}>
								{hint.text}
							</div>
							{
								hint.hours
									? (
										<div className={styles.tableColumn}>
											{hint.hours}
											h
										</div>
									)
									: null
							}
						</div>
					);
				})
			}
		</>
	);
};

export default CalendarSubHeaderCellTooltip;
