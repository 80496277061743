/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

class BaseChecker extends Component {
	static defaultProps = {
		findIndexCallback: (id) => {
			return (el) => {
				return el === id;
			};
		},
		valueName: "id",
	};

	onClick = () => {
		const {
			add,
			remove,
			arr,
			findIndexCallback,
			valueName,
		} = this.props;

		if (!arr.some(findIndexCallback(this.props[valueName]))) {
			add(this.props[valueName]);
		} else {
			remove(this.props[valueName]);
		}
	};

	render() {
		const {
			id,
			arr,
			findIndexCallback,
			className,
			valueName,
			disabled,
		} = this.props;

		return (
			<Checkbox
				id={id}
				cx={
					classNames(
						"multiple-choise-checker",
						className,
					)
				}
				onValueChange={this.onClick}
				value={arr.some(findIndexCallback(this.props[valueName]))}
				isDisabled={disabled}
			/>
		);
	}
}

export default BaseChecker;
