/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import "./MultipleChoicePanel.css";

/**
 * @typedef {Object} MultipleChoicePanelProps
 * @property {unknown[]} arr
 * @property {string} [className]
 * @property {import("react").CSSProperties} [style]
 * @property {import("types/common").WithChildren["children"]} children
 */

/** @type {import("react").FC<MultipleChoicePanelProps>} */
const MultipleChoicePanel = ({
	arr,
	children,
	className,
	style,
}) => {
	return (
		<div
			className={
				classNames(
					"multiple-choice-panel",
					className,
					arr && arr.length && "show",
				)
			}
			style={style}
		>
			<div className="top-line"/>
			<div className="content">
				{children}
			</div>
		</div>
	);
};

export default MultipleChoicePanel;
