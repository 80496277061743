/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	getDayOfYear,
	isSameMonth,
} from "date-fns";

import {
	InclusivitySymbol,
	getDateYear,
	getDayOfMonth,
	getDifferenceInDays,
	isBetween,
	parseISODate,
} from "models/dates-and-time/utils/common";

import {
	DATE_ACTION_TYPES,
} from "../constants";
import {
	getDatesForCalendar,
	getDays,
	getStartAndEndDates,
} from "./Days.helpers";

import "./Days.css";

const Days = ({
	date,
	viewDate,
	valueClickHandler,
	period,
}) => {
	const {
		startOfViewDate,
		endOfViewDate,
	} = getDatesForCalendar(viewDate);
	const {
		startDate,
		endDate,
	} = getStartAndEndDates(date, period);
	const numTotalDays = getDifferenceInDays({
		date: endOfViewDate,
		dateToCompare: startOfViewDate,
	}) + 1;

	const calendarDays = getDays(numTotalDays, startOfViewDate);

	const renderCell = (day) => {
		const dayOfMonth = getDayOfMonth(day);
		const dayOfYear = getDayOfYear(parseISODate(day));
		const year = getDateYear(day);

		return (
			<div
				key={dayOfYear}
				onClick={() => {
					return valueClickHandler({
						actionType: DATE_ACTION_TYPES.SET_YEAR_AND_DAY,
						dayOfYear,
						year,
					});
				}}
				className={
					classNames(
						"item-day",
						{
							selected: isBetween({
								date: day,
								from: startDate,
								to: endDate,
								inclusivity: `${InclusivitySymbol.EXCLUDE_START}${InclusivitySymbol.EXCLUDE_END}`,
							}),
							"in-current-month": isSameMonth(
								parseISODate(viewDate),
								parseISODate(day),
							),
						},
					)
				}
			>
				{dayOfMonth}
			</div>
		);
	};

	const renderDays = () => {
		const days = calendarDays.reverse();

		return days.map((week, index) => {
			return (
				<article
					className="calendar-row"
					key={index}
				>
					{week.map(renderCell)}
				</article>
			);
		});
	};

	return (
		<section className="calendar-days">
			{renderDays()}
		</section>
	);
};

export default Days;
