/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import {
	getDateYear,
} from "models/dates-and-time/utils/common";

import {
	DATE_ACTION_TYPES,
	NUMBER_OF_COLUMNS,
} from "../constants";

import "./Years.css";

const NUMBER_OF_ROWS_FOR_YEAR_GRID = 3;

const YEARS_AGO = 5;

const Years = ({
	date,
	viewDate,
	valueClickHandler,
}) => {
	const selectedYear = getDateYear(date);
	const viewYear = getDateYear(viewDate);
	const firstYear = viewYear - YEARS_AGO;

	const renderYears = () => {
		return (
			(
				new Array(NUMBER_OF_ROWS_FOR_YEAR_GRID))
				.fill(
					new Array(NUMBER_OF_COLUMNS)
						.fill(firstYear),
				)
				.map((years4, indexOf4) => {
					return (
						<article
							className="calendar-row"
							key={indexOf4}
						>
							{
								years4.map((firstYear, indexOf1) => {
									const year = (
										firstYear
										+ (indexOf4 * NUMBER_OF_COLUMNS)
										+ Number(indexOf1)
									);

									return (
										<div
											className={
												classNames(
													"year-item",
													{
														selected: selectedYear === year,
													},
												)
											}
											data-value-key="year"
											onClick={() => {
												return valueClickHandler({
													actionType: DATE_ACTION_TYPES.SET_YEAR,
													year,
												});
											}}
											key={year}
										>
											{year}
										</div>
									);
								})
							}
						</article>
					);
				})
		);
	};

	return (
		<section className="calendar-years">
			{renderYears()}
		</section>
	);
};

export default Years;
