/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import {
	MONTH_NAMES_SHORTS,
} from "models/dates-and-time/constants";
import {
	getDateYear,
	getMonthNumber,
} from "models/dates-and-time/utils/common";

import {
	DATE_ACTION_TYPES,
	NUMBER_OF_COLUMNS,
} from "../constants";

import "./Months.css";

const Months = ({
	date,
	viewDate,
	valueClickHandler,
}) => {
	const selectedYear = getDateYear(date);
	const viewMonth = getMonthNumber(viewDate);
	const viewYear = getDateYear(viewDate);

	const splitMonthsNames = (arrMonthNames) => {
		return (
			arrMonthNames.reduce(
				(arrMonth, monthName, index) => {
					const indOfCombineMonths = Math.floor(index / NUMBER_OF_COLUMNS);

					arrMonth[indOfCombineMonths] = [
						...arrMonth[indOfCombineMonths],
						monthName,
					];

					return arrMonth;
				},
				[
					[],
					[],
					[],
				],
			)
		);
	};

	const renderMonths = () => {
		const splitedMonthsNames = splitMonthsNames(MONTH_NAMES_SHORTS);

		return splitedMonthsNames.map((groupMonth, indexOf4) => {
			return (
				<article
					className="calendar-row"
					key={indexOf4}
				>
					{
						groupMonth.map((monthName, indexOf1) => {
							const month = (indexOf4 * NUMBER_OF_COLUMNS) + Number(indexOf1);

							return (
								<div
									className={
										classNames(
											"month-item",
											{
												selected: (
													month === viewMonth
													&& selectedYear === viewYear
												),
											},
										)
									}
									onClick={() => {
										return valueClickHandler({
											actionType: DATE_ACTION_TYPES.SET_MONTH,
											month,
										});
									}}
									key={monthName}
								>
									{monthName}
								</div>
							);
						})
					}
				</article>
			);
		});
	};

	return (
		<section className="calendar-months">
			{renderMonths()}
		</section>
	);
};

export default Months;
