/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	Link,
} from "react-router-dom";

import {
	generatePackagesIssueMappingPageUrl,
} from "pages/utils/generate-packages-issue-mapping-page-url";

import Tooltip from "../tooltip/Tooltip";

import "./IssueMappingLink.css";

const IssueMappingLink = ({
	projectId,
	className,
}) => {
	return (
		<span
			data-tooltip="left"
			className={
				classNames(
					className,
					"issue-mapping-link",
				)
			}
		>
			<span className="issue-mapping-icon"/>
			<Link
				to={
					generatePackagesIssueMappingPageUrl({
						projectId,
					})
				}
				target="_blank"
			>
				Issues mapping
			</Link>
			<Tooltip text="Click to open Time-Jira issues mapping"/>
		</span>
	);
};

export default IssueMappingLink;
