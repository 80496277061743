/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as iconLeft,
} from "@epam/assets/icons/common/navigation-chevron-left-24.svg";
import {
	ReactComponent as iconRight,
} from "@epam/assets/icons/common/navigation-chevron-right-24.svg";
import {
	Button,
	FlexCell,
	FlexRow,
	FlexSpacer,
	LinkButton,
} from "@epam/loveship";
import {
	arrayToMatrix,
} from "@epam/uui-core";
import classNames from "classnames";

import {
	MONTH_NAMES,
} from "models/dates-and-time/constants";
import {
	getDateYear,
	getMonthNumber,
} from "models/dates-and-time/utils/common";

import {
	MONTH_ROW_LENGTH,
} from "../../constants";

import styles from "./MonthSelection.module.css";

const MonthSelection = ({
	date,
	onValueChange,
	year,
	setYear,
	onYearClick,
	startYear,
}) => {
	const setPrevYear = () => {
		if (year > startYear) {
			setYear(year - 1);
		}
	};

	const setNextYear = () => {
		setYear(year + 1);
	};

	const setMonth = (month) => {
		return () => {
			onValueChange(month);
		};
	};

	return (
		<>
			<FlexRow
				padding="24"
				vPadding="24"
			>
				<Button
					icon={iconLeft}
					fill="light"
					size="36"
					color="night700"
					onClick={setPrevYear}
					isDisabled={year <= startYear}
				/>
				<FlexSpacer/>
				<LinkButton
					caption={year}
					onClick={onYearClick}
				/>
				<FlexSpacer/>
				<Button
					icon={iconRight}
					fill="light"
					size="36"
					color="night700"
					onClick={setNextYear}
				/>
			</FlexRow>
			{
				arrayToMatrix(MONTH_NAMES, MONTH_ROW_LENGTH)
					.map((row, rowIndex) => {
						return (
							<FlexRow
								padding="24"
								key={rowIndex}
								vPadding="12"
							>
								{
									row.map((monthName, index) => {
										const month = index + (rowIndex * MONTH_ROW_LENGTH);

										return (
											<FlexCell
												key={index}
												textAlign="center"
												shrink={0}
												width={95}
											>
												{
													(
														month === getMonthNumber(date)
														&& year === getDateYear(date)
													)
														? (
															<Button
																cx={styles.monthName}
																caption={monthName}
																shape="round"
																onClick={setMonth(month)}
															/>
														)
														: (
															<LinkButton
																onClick={setMonth(month)}
																caption={monthName}
																color="night600"
																cx={
																	classNames(
																		styles.monthName,
																		{
																			[styles.fontWeightRegular]: (
																				month !== getMonthNumber(new Date())
																				|| year !== getDateYear(new Date())
																			),
																		},
																	)
																}
															/>
														)
												}
											</FlexCell>
										);
									})
								}
							</FlexRow>
						);
					})
			}
		</>
	);
};

export default MonthSelection;
