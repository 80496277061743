/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const DISPLAYED_YEARS_NUMBER = 12;
const YEAR_ROW_LENGTH = 4;
const MONTH_ROW_LENGTH = 3;
const DEFAULT_START_YEAR = 2001;

export {
	DISPLAYED_YEARS_NUMBER,
	YEAR_ROW_LENGTH,
	MONTH_ROW_LENGTH,
	DEFAULT_START_YEAR,
};
