/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
} from "@epam/loveship";
import {
	Component,
} from "react";

class Checker extends Component {
	static defaultProps = {
		findIndexCallback: (id) => {
			return (el) => {
				return el === id;
			};
		},
		valueName: "id",
	};

	onValueChange = (value) => {
		const {
			add,
			remove,
			valueName,
		} = this.props;

		if (value) {
			add(this.props[valueName]);
		} else {
			remove(this.props[valueName]);
		}
	};

	render() {
		const {
			arr,
			findIndexCallback,
			valueName,
			disable,
		} = this.props;

		return (
			<Checkbox
				cx={this.props.cx}
				value={arr.some(findIndexCallback(this.props[valueName]))}
				onValueChange={this.onValueChange}
				isDisabled={disable}
			/>

		);
	}
}

export default Checker;
