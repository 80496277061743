/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const DATE_ACTION_TYPES = {
	SET_MONTH: "set-month",
	SET_YEAR: "set-year",
	SET_YEAR_AND_DAY: "set-year-and-day",
};

const NUMBER_OF_COLUMNS = 4;

export {
	DATE_ACTION_TYPES,
	NUMBER_OF_COLUMNS,
};
