/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	svc,
} from "constants/services";

import MarkupActionsModal from "./MarkupActionsModal";

export const showMarkupActionsModal = ({
	id,
	action,
	markupLevel,
	statuses,
	callback,
	filterDates,
	worklogDate,
}) => {
	if (
		markupLevel
		&& action
	) {
		return svc.uuiModals.show((props) => {
			return (
				<MarkupActionsModal
					{...props}
					action={action}
					markupLevel={markupLevel}
					statuses={
						statuses.sort(
							(status1, status2) => {
								return status1.name.toLowerCase().localeCompare(
									status2.name.toLowerCase(),
								);
							},
						)
					}
					filterDates={filterDates}
					worklogDate={worklogDate}
				/>
			);
		})
			.then((data) => {
				return callback({
					...data,
					id,
				});
			})
			.catch((data) => {
				return data;
			});
	}
};
