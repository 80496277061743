/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	IconContainer,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	ReactComponent as FilterIcon,
} from "icons/filter-18.svg";
import {
	ReactComponent as FilterAppliedIcon,
} from "icons/filter-applied-21x20.svg";
import {
	type FilterIconButtonProps,
} from "types/filter";

import styles from "./FilterIconButton.module.css";

const FilterIconButton: FC<FilterIconButtonProps> = ({
	onClick,
	customFilterApplied,
}) => {
	return (
		<div
			className={styles["filter-icon-button"]}
			onClick={onClick}
		>
			{
				customFilterApplied
					? <IconContainer icon={FilterAppliedIcon}/>
					: <IconContainer icon={FilterIcon}/>
			}
		</div>
	);
};

export {
	FilterIconButton,
};
