/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type FC,
} from "react";

import {
	type WithClassName,
} from "types/common";

import styles from "./no-filter-data.module.css";

interface NoFilterDataProps extends WithClassName {
	isLast?: boolean;
	text?: string;
}

const NoFilterData: FC<NoFilterDataProps> = ({
	isLast = false,
	text = "No results found. Please check filters selected.",
	className,
}) => {
	return (
		<div
			className={
				classNames(
					styles.noFilterData,
					{
						[styles.isNotLast]: !isLast,
					},
					className,
				)
			}
		>
			{text}
		</div>
	);
};

export {
	NoFilterData,
};
