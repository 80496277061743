/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const taggedDropdownValueChanged = (propertyName, callback) => {
	return (propertyValue) => {
		if (callback) {
			callback(propertyName, propertyValue);
		}
	};
};

const setValue = (callback, propertyName, propertyValue) => {
	return () => {
		if (callback) {
			callback(propertyName, propertyValue);
		}
	};
};

/**
 * @template Data
 * @param {Data} filterValuesDefault
 * @param {string} localStorageKey
 * @returns {Data}
 * @deprecated Use `getDataFromLocalStorage` instead.
 */
const getFilterValuesFromLocalStorage = (
	filterValuesDefault,
	localStorageKey,
) => {
	let filterValues = filterValuesDefault;
	let filterDataStr;

	if (localStorageKey) {
		filterDataStr = localStorage.getItem(localStorageKey);
	}

	if (filterDataStr) {
		filterValues = {
			...filterValuesDefault,
			...JSON.parse(filterDataStr),
		};
	}

	return filterValues;
};

export {
	taggedDropdownValueChanged,
	setValue,
	getFilterValuesFromLocalStorage,
};
