/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
	SnackbarCard,
	Text,
} from "@epam/loveship";

const DefaultNotification = ({
	type,
	message,
	...data
}) => {
	return (
		<SnackbarCard
			{...data}
			snackType={type}
		>
			<FlexRow
				vPadding="12"
				padding="12"
			>
				<Text fontSize="14">
					{message}
				</Text>
			</FlexRow>
		</SnackbarCard>
	);
};

export default DefaultNotification;
