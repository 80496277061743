/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Badge,
	FlexRow,
	ScrollBars,
	Text,
} from "@epam/loveship";
import {
	Component,
} from "react";

import {
	MAX_STRUCTURE_LEVEL,
} from "../../consts";
import Stream from "./Stream";

/**
 * @typedef {Object} StreamDropdownContainerProps
 * @property {boolean} isDisabled
 */

/**
 * @extends {Component<StreamDropdownContainerProps, Object>}
 */
class StreamDropdownContainer extends Component {
	state = {
		selection: [],
		indeterminate: [],
	};

	componentDidMount() {
		const {
			selectedValues,
			data,
		} = this.props;

		this.processSelected(data, selectedValues);
	}

	componentDidUpdate(prevProps) {
		const {
			selectedValues,
			data,
		} = this.props;

		if (
			data !== prevProps.data
			|| selectedValues !== prevProps.selectedValues
		) {
			this.processSelected(data, selectedValues);
		}
	}

	processSelected = (data, selectedValues) => {
		const selection = [];
		const indeterminate = [];

		Object.keys(data)
			.forEach((streamId) => {
				if (
					(
						selectedValues
						&& selectedValues[streamId]
						&& !data[streamId].children
						&& data[streamId].level <= MAX_STRUCTURE_LEVEL
					)
					|| (
						selectedValues
						&& selectedValues[streamId]
						&& data[streamId].children
						&& data[streamId].level === MAX_STRUCTURE_LEVEL
					)
				) {
					selection.push({
						...data[streamId],
					});
				}

				const contCheckedChildren = data[streamId].children
					? data[streamId].children.filter((stream) => {
						return (
							selectedValues
							&& selectedValues[stream]
						);
					}).length
					: 0;

				if (
					contCheckedChildren > 0
					&& contCheckedChildren < data[streamId].children.length
				) {
					indeterminate[streamId] = true;

					if (data[streamId].parents) {
						data[streamId].parents.forEach((id) => {
							indeterminate[id] = true;
						});
					}
				}

				if (
					contCheckedChildren > 0
					&& contCheckedChildren === data[streamId].children.length
				) {
					this.checkStream(streamId, true);
				}
			});

		this.setState({
			indeterminate,
			selection,
		});
	};

	checkStream = (id, isAllChildChecked = false) => {
		const {
			selectedValues,
			onChange,
			data,
		} = this.props;

		if (
			isAllChildChecked
			&& onChange
		) {
			onChange(selectedValues, id);

			return;
		}

		const newValue = {
			...selectedValues,
		};
		const value = Boolean(!newValue[id]);

		const checkChildrenStream = (streamId, data) => {
			const children = data[streamId].children
				? data[streamId].children
				: [];

			children.forEach((childrenId) => {
				checkChildrenStream(childrenId, data);
			});

			if (
				!newValue[streamId]
				&& value
			) {
				newValue[streamId] = value;
			} else
			if (
				newValue[streamId]
				&& !value
			) {
				delete newValue[streamId];
			}
		};

		const checkParentsStream = (streamId, data) => {
			if (value) {
				if (data[streamId].parent) {
					const parentId = data[streamId].parent;

					if (
						data[parentId].children.every((childId) => {
							return newValue[childId];
						})
					) {
						newValue[parentId] = value;

						checkParentsStream(parentId, data);
					}
				}
			} else if (data[streamId].parents) {
				data[streamId].parents.forEach((id) => {
					return delete newValue[id];
				});
			}
		};

		checkChildrenStream(id, data);

		checkParentsStream(id, data);

		if (onChange) {
			onChange(newValue, id);
		}
	};

	render() {
		const {
			selectedValues,
			data,
			rootStreams,
			isShowBadgeCount,
			title,
			isDisabled,
		} = this.props;
		const {
			selection,
			indeterminate,
		} = this.state;

		return (
			<ScrollBars>
				{
					(
						title
						|| isShowBadgeCount
					)
						? (
							<FlexRow padding="12">
								{
									title
										? (
											<Text fontSize="14">
												{title}
											</Text>
										)
										: null
								}
								{
									(
										isShowBadgeCount
										&& selection
										&& selection.length
									)
										? (
											<Badge
												caption={`+${selection.length}`}
												shape="round"
												color="night400"
											/>
										)
										: null
								}
							</FlexRow>
						)
						: null
				}
				{
					rootStreams.sort((id1, id2) => {
						const el1 = data[id1];
						const el2 = data[id2];

						if (el1.name === "Root positions") {
							return -1;
						}

						if (el2.name === "Root positions") {
							return 1;
						}

						return el1.name.trim().localeCompare(el2.name.trim());
					})
						.map((stream) => {
							return (
								<Stream
									key={stream}
									selectedValues={selectedValues}
									data={data}
									stream={stream}
									checkStream={this.checkStream}
									indeterminate={indeterminate}
									isDisabled={isDisabled}
								/>
							);
						})
				}
			</ScrollBars>
		);
	}
}

export default StreamDropdownContainer;
