/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	PureComponent,
} from "react";

import {
	addDateMonths,
	addDateYears,
	formatDefaultDate,
	setDateDayOfYear,
	setDateMonth,
	setDateYear,
	subDateMonths,
	subDateYears,
} from "models/dates-and-time/utils/common";

import {
	PERIODS,
} from "../../../environment/journal_environment";
import {
	DATE_ACTION_TYPES,
} from "./constants";
import VIEW_MODES, {
	findViewMode,
	getViewMode,
	inViewMode,
	outViewMode,
} from "./DatePicker.helpers";
import Days from "./days/Days";
import Header from "./header/Header";
import Months from "./months/Months";
import Years from "./years/Years";

import "./DatePicker.css";

const DIRECTION_UNIT_TYPES = {
	MONTHS: "months",
	YEARS: "years",
};

const DIRECTION_METHODS = {
	SUBTRACT: "subtract",
	ADD: "add",
};

const Components = {
	MONTH: Days,
	YEAR: Months,
	TWELVE_YEAR: Years,
};

class DatePicker extends PureComponent {
	state = {
		viewMode: PERIODS.WEEK,
		date: formatDefaultDate(new Date()),
		propDate: null,
		propPeriod: PERIODS.WEEK,
	};

	static getDerivedStateFromProps(props, state) {
		const {
			date,
			period,
		} = props;
		const {
			propDate,
			propPeriod,
		} = state;

		if (
			date !== propDate
			|| propPeriod !== period
		) {
			return {
				date: date || formatDefaultDate(new Date()),
				viewMode: getViewMode(period),
				propDate: date,
				propPeriod: period,
			};
		}

		return null;
	}

	directionClickHandler = ({
		target: {
			dataset: {
				method,
			},
		},
	}) => {
		const {
			units,
			num,
		} = findViewMode(this.state.viewMode);
		const {
			date,
		} = this.state;

		let updatedDate;

		if (units === DIRECTION_UNIT_TYPES.MONTHS) {
			if (method === DIRECTION_METHODS.SUBTRACT) {
				updatedDate = subDateMonths({
					date,
					amount: num,
				});
			} else {
				updatedDate = addDateMonths({
					date,
					amount: num,
				});
			}
		}

		if (units === DIRECTION_UNIT_TYPES.YEARS) {
			if (method === DIRECTION_METHODS.SUBTRACT) {
				updatedDate = subDateYears({
					date,
					amount: num,
				});
			} else {
				updatedDate = addDateYears({
					date,
					amount: num,
				});
			}
		}

		this.setState({
			date: formatDefaultDate(updatedDate),
		});
	};

	headerClickHandler = () => {
		this.setState(({
			viewMode,
		}) => {
			return {
				viewMode: outViewMode(viewMode),
			};
		});
	};

	valueClickHandler = (setParam) => {
		const {
			viewMode,
			date,
		} = this.state;
		const {
			period,
			setCalendar,
		} = this.props;
		let nextDate;

		switch (setParam.actionType) {
			case DATE_ACTION_TYPES.SET_MONTH: {
				nextDate = setDateMonth({
					date,
					value: setParam.month,
				});

				break;
			}

			case DATE_ACTION_TYPES.SET_YEAR: {
				nextDate = setDateYear({
					date,
					value: setParam.year,
				});

				break;
			}

			case DATE_ACTION_TYPES.SET_YEAR_AND_DAY: {
				nextDate = setDateDayOfYear({
					date: setDateYear({
						date,
						value: setParam.year,
					}),
					value: setParam.dayOfYear,
				});

				break;
			}

			default: {
				nextDate = new Date();
			}
		}

		const showNext = (
			viewMode === VIEW_MODES.TWELVE_YEAR.name
			|| (
				viewMode === VIEW_MODES.YEAR.name
				&& period !== PERIODS.MONTH
			)
		);

		if (showNext) {
			this.setState({
				date: nextDate,
				viewMode: inViewMode(viewMode),
			});
		} else {
			setCalendar(nextDate);
		}
	};

	renderCalendar = (viewMode) => {
		const props = {
			date: this.props.date,
			period: this.props.period,
			viewDate: this.state.date,
			valueClickHandler: this.valueClickHandler,
		};
		const CalendarComponent = Components[viewMode];

		return <CalendarComponent {...props}/>;
	};

	render() {
		const {
			viewMode,
			date,
		} = this.state;
		const {
			period,
			open,
		} = this.props;
		const label = viewMode && findViewMode(viewMode).getLabel(date);

		return (
			<div
				className={
					classNames(
						"date-picker",
						{
							open,
						},
					)
				}
				data-period={period}
			>
				{
					viewMode
						? (
							<div>
								<Header
									label={label}
									directionClickHandler={this.directionClickHandler}
									headerClickHandler={this.headerClickHandler}
								/>
								<main>
									{this.renderCalendar(viewMode)}
								</main>
							</div>
						)
						: (
							<p className="placeholder">
								Please choose a
								<br/>
								“Week / 2Weeks / Month”
								<br/>
								to use a date filter
							</p>
						)
				}
			</div>
		);
	}
}

export default DatePicker;
