/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";

import "./Tooltip.css";

const Tooltip = ({
	text,
	header,
	className = "",
}) => {
	return (
		<div
			className={
				classNames(
					"tooltip",
					className,
					{
						hide: !text,
					},
				)
			}
		>
			<div
				className={
					classNames(
						"tooltip-content",
						{
							"has-header": Boolean(header),
						},
					)
				}
			>
				<p
					className={
						classNames(
							"tooltip-header",
							{
								hide: !header,
							},
						)
					}
				>
					{header}
				</p>
				<p
					className="tooltip-text"
					style={{
						lineHeight: "20px",
					}}
				>
					{text}
				</p>
			</div>
		</div>
	);
};

export default Tooltip;
