/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CalendarIcon,
} from "@epam/assets/icons/common/action-calendar-18.svg";
import {
	ReactComponent as NavigationLeftIcon,
} from "@epam/assets/icons/common/navigation-chevron-left-18.svg";
import {
	ReactComponent as NavigationRightIcon,
} from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import classNames from "classnames";
import isBoolean from "lodash/isBoolean";
import {
	PureComponent,
} from "react";

import {
	Operation,
} from "models/dates-and-time/constants";
import {
	arithmeticFromAndTo,
	calcFromAndTo,
	formatJournalHeaderDate,
} from "models/dates-and-time/utils/common";

import DatePicker from "../date-picker/DatePicker";

import "./DateNavigation.css";

class DateNavigation extends PureComponent {
	state = {
		isCalendarOpen: false,
	};

	componentDidMount() {
		document.addEventListener("click", this.checkIfClickedOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.checkIfClickedOutside, true);
	}

	checkIfClickedOutside = ({
		target,
	}) => {
		if (
			this.datePickerContainer
			&& !this.datePickerContainer.contains(target)
		) {
			this.toggleCalendarView(false);
		}
	};

	toggleCalendarView = (state) => {
		if (isBoolean(state)) {
			this.setState({
				isCalendarOpen: state,
			});
		} else {
			this.setState((prevState) => {
				return {
					isCalendarOpen: !prevState.isCalendarOpen,
				};
			});
		}
	};

	onDateValueClick = (event) => {
		event.stopPropagation();

		this.toggleCalendarView();
	};

	setCalendar = (date) => {
		const {
			period,
			onChange,
		} = this.props;
		const {
			fromDate,
			toDate,
		} = calcFromAndTo({
			period,
			date,
		});

		this.setState({
			isCalendarOpen: false,
		});

		if (onChange) {
			onChange({
				fromDate,
				toDate,
			});
		}
	};

	moveBack = () => {
		const {
			period,
			fromDate,
		} = this.props;

		this.setCalendar(arithmeticFromAndTo({
			operation: Operation.SUB_MONTH,
			period,
			fromDate,
		}).toDate);
	};

	moveForward = () => {
		const {
			period,
			fromDate,
		} = this.props;

		this.setCalendar(arithmeticFromAndTo({
			operation: Operation.ADD_MONTH,
			period,
			fromDate,
		}).toDate);
	};

	render() {
		const {
			period,
			fromDate,
			toDate,
		} = this.props;
		const {
			isCalendarOpen,
		} = this.state;
		const displayFrom = formatJournalHeaderDate(fromDate);
		const displayTo = formatJournalHeaderDate(toDate);

		return (
			<div
				className={
					classNames(
						"date-navigation",
						this.props.cx,
					)
				}
			>
				<div
					onClick={this.moveBack}
					className="prev-period"
				>
					<NavigationLeftIcon/>
				</div>
				<time>
					<CalendarIcon
						onClick={this.onDateValueClick}
						className="calendar"
					/>
					<div
						className="calendar-container"
						ref={(ref) => {
							this.datePickerContainer = ref;
						}}
					>
						<span
							className="date-value"
							onClick={this.onDateValueClick}
						>
							{`${displayFrom} - ${displayTo}`}
						</span>
						<DatePicker
							date={toDate}
							period={period}
							open={isCalendarOpen}
							setCalendar={this.setCalendar}
						/>
					</div>
				</time>
				<div
					onClick={this.moveForward}
					className="next-period"
				>
					<NavigationRightIcon/>
				</div>
			</div>
		);
	}
}

export default DateNavigation;
