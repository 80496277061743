/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import "./Header.css";

const Header = ({
	label,
	directionClickHandler,
	headerClickHandler,
}) => {
	return (
		<header className="date-picker-header">
			<nav>
				<span
					className="prev"
					data-method="subtract"
					onClick={directionClickHandler}
				>
					«
				</span>
				<span
					className="label"
					onClick={headerClickHandler}
				>
					{label}
				</span>
				<span
					className="next"
					data-method="add"
					onClick={directionClickHandler}
				>
					»
				</span>
			</nav>
		</header>
	);
};

export default Header;
