/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexSpacer,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ScrollBars,
	Text,
} from "@epam/loveship";
import {
	type IModal,
} from "@epam/uui-core";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	Component,
	type ReactNode,
} from "react";
import {
	flushSync,
} from "react-dom";

import {
	type WithChildren,
} from "types/common";
import {
	getDataFromLocalStorage,
} from "utils/get-data-from-local-storage";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./filters-panel.module.css";

type ValidationErrorMessage = string;

type ValidationErrors<FilterValues> = Record<
	keyof FilterValues,
	ValidationErrorMessage
>;

type ValidateFilterValues<FilterValues> = (
	filterValues: FilterValues,
) => ValidationErrors<FilterValues>;

type GetIsFilterChanged<FilterValues> = (
	filterValues: FilterValues,
) => boolean;

type FilterLocalStorageKey = string;

type ApplyFilter<FilterValues> = (
	filterValues: FilterValues,
) => void;

type SetFilterValue<FilterValues> = <PropertyName extends keyof FilterValues>(
	propertyName: PropertyName,
	propertyValue: FilterValues[PropertyName],
) => void;

type ValidationErrorsNullable<FilterValues> =
	| ValidationErrors<FilterValues>
	| null;

type FiltersPanelContentRenderer<FilterValues> = (
	filterValues: FilterValues,
	setFilterValue: SetFilterValue<FilterValues>,
	errors: ValidationErrorsNullable<FilterValues>,
) => ReactNode;

interface FilterPanelOwnProps<FilterValues> {
	filterValuesDefault: FilterValues;
	validate?: ValidateFilterValues<FilterValues>;
	localStorageKey: FilterLocalStorageKey;
	applyFilter?: ApplyFilter<FilterValues>;
	getFilterValuesFromLocalStorage?: () => FilterValues;
	updateFilterValuesInLocalStorage?: (filterValues: FilterValues) => void;
	render: FiltersPanelContentRenderer<FilterValues>;
}

interface FilterPanelProps<FilterValues> extends
	WithChildren,
	FilterPanelOwnProps<FilterValues>,
	IModal<undefined> {}

interface FilterPanelState<FilterValues> {
	filterValues: FilterValues;
	errors: ValidationErrorsNullable<FilterValues>;
}

class FiltersPanel<FilterValues> extends Component<
	FilterPanelProps<FilterValues>,
	FilterPanelState<FilterValues>
> {
	public constructor(
		props: FilterPanelProps<FilterValues>,
	) {
		super(props);

		const {
			getFilterValuesFromLocalStorage = this.getFilterValuesFromLocalStorage,
		} = props;

		const filterValues = getFilterValuesFromLocalStorage();

		const errors = this.getErrors(filterValues);

		this.state = {
			filterValues,
			errors,
		};
	}

	public componentDidUpdate(
		props: FilterPanelProps<FilterValues>,
	): void {
		const {
			localStorageKey,
			getFilterValuesFromLocalStorage = this.getFilterValuesFromLocalStorage,
		} = this.props;

		if (localStorageKey !== props.localStorageKey) {
			const filterValues = getFilterValuesFromLocalStorage();

			const errors = this.getErrors(filterValues);

			this.setState({
				filterValues,
				errors,
			});
		}
	}

	private readonly getFilterValuesFromLocalStorage = (): FilterValues => {
		const {
			localStorageKey,
			filterValuesDefault,
		} = this.props;

		return getDataFromLocalStorage({
			key: localStorageKey,
			defaultValue: cloneDeep(filterValuesDefault),
		});
	};

	private readonly getErrors = (filterValues: FilterValues): ValidationErrors<FilterValues> | null => {
		const {
			validate,
		} = this.props;

		if (isUndefined(validate)) {
			return null;
		}

		return validate(filterValues);
	};

	private readonly applyFilter = (): void => {
		const {
			localStorageKey,
			applyFilter,
			success,
			updateFilterValuesInLocalStorage,
		} = this.props;
		const {
			filterValues,
		} = this.state;

		let isValid = true;

		const errors = this.getErrors(filterValues);

		if (
			!isNull(errors)
			&& !isEmpty(errors)
		) {
			isValid = false;
		}

		if (isValid) {
			if (!isUndefined(updateFilterValuesInLocalStorage)) {
				updateFilterValuesInLocalStorage(filterValues);
			} else {
				localStorage.setItem(localStorageKey, JSON.stringify(filterValues));
			}

			applyFilter?.({
				...this.state.filterValues,
			});

			success(undefined);
		} else {
			this.setState({
				errors,
			});
		}
	};

	private readonly clearFilter = (): void => {
		flushSync(() => {
			this.setState({
				filterValues: {
					...this.props.filterValuesDefault,
				},
			});
		});

		this.applyFilter();
	};

	private readonly setFilterProperty = <
		PropertyName extends keyof FilterValues,
	>(
		propertyName: PropertyName,
		propertyValue: FilterValues[PropertyName],
	): void => {
		let {
			filterValues,
		} = this.state;

		const obj: Partial<FilterValues> = {};

		obj[propertyName] = propertyValue;

		filterValues = {
			...filterValues,
			...obj,
		};

		const errors = this.getErrors(filterValues);

		this.setState({
			filterValues,
			errors,
		});
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	public render(): ReactNode {
		const {
			children,
			render,
			...properties
		} = this.props;
		const {
			filterValues,
			errors,
		} = this.state;

		return (
			<ModalBlocker {...properties}>
				<aside
					className={styles.filtersPanel}
				>
					<ModalHeader
						borderBottom={true}
						rawProps={{
							"data-name": toDataAttribute("Filters panel header"),
						}}
						onClose={properties.abort}
					>
						<Text
							fontSize="18"
							lineHeight="24"
							size="24"
						>
							Filters
						</Text>
					</ModalHeader>

					<ScrollBars>
						<div
							className={styles.content}
						>
							{render(filterValues, this.setFilterProperty, errors)}
						</div>
					</ScrollBars>

					<ModalFooter
						borderTop={true}
						rawProps={{
							"data-name": toDataAttribute("Filters panel footer"),
						}}
					>
						<FlexSpacer/>
						<Button
							color="gray"
							fill="white"
							caption="Reset filters"
							onClick={this.clearFilter}
						/>
						<Button
							color="grass"
							caption="Apply filters"
							onClick={this.applyFilter}
						/>
					</ModalFooter>
				</aside>
			</ModalBlocker>
		);
	}
}

export {
	FiltersPanel,
	type FilterPanelOwnProps,
	type ValidateFilterValues,
	type GetIsFilterChanged,
	type FilterLocalStorageKey,
	type ApplyFilter,
	type SetFilterValue,
	type ValidationErrorsNullable,
	type FiltersPanelContentRenderer,
};
