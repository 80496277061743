/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
} from "@epam/loveship";

import {
	SwitcherWithTooltip,
} from "../switcher-with-tooltip/SwitcherWithTooltip";

export const SwitchersWithTooltip = ({
	switchers,
}) => {
	if (
		!switchers
		|| !switchers.length
	) {
		return (
			<div>
				no switchers
			</div>
		);
	}

	return (
		<>
			{
				switchers.map((switcher, index) => {
					return (
						<FlexRow
							key={index}
							vPadding="12"
							padding="6"
							rawProps={{
								role: "switch",
							}}
						>
							<SwitcherWithTooltip
								label={switcher.label}
								value={switcher.value}
								onValueChange={switcher.onValueChange}
								tooltip={switcher.tooltip}
							/>
						</FlexRow>
					);
				})
			}
		</>
	);
};
