/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isString from "lodash/isString";
import noop from "lodash/noop";

import {
	svc,
} from "constants/services";

import {
	NOTIFICATION_TYPES,
	TIMEOUT,
} from "./const";
import DefaultNotification from "./DefaultNotification";

/**
 * @typedef {import("@epam/uui-core").NotificationParams} UUINotificationParams
 */

/**
 * @typedef {typeof import("@epam/loveship").SnackbarCard} SnackbarCard
 */

/**
 * @typedef {import("react").ComponentProps<SnackbarCard>} SnackbarCardProps
 */

/**
 * @typedef {Object} AdditionalProps
 * @property {SnackbarCardProps["snackType"]} type
 * @property {SnackbarCardProps["children"]} message
 */

/**
 * @typedef {Omit<SnackbarCard, "snackType" | "children"> & AdditionalProps} DefaultNotificationProps
 */

const removeNotificationByTag = (tag) => {
	const notification = svc
		.uuiNotifications
		.getNotifications()
		.find((el) => {
			return (
				el.config.tag
				&& el.config.tag === tag
			);
		});

	if (
		notification
		&& notification.props.id
	) {
		svc.uuiNotifications.remove(notification.props.id);
	}
};

const runWhenSameTagNotExists = (tag, callback) => {
	const sameNot = svc
		.uuiNotifications
		.getNotifications()
		.find((el) => {
			return (
				el.config.tag
				&& el.config.tag === tag
			);
		});

	if (
		!sameNot
		&& callback
	) {
		callback();
	}
};

/**
 * @typedef {Object} CustomNotificationParams
 * @property {string} [tag]
 */

/**
 * @typedef {UUINotificationParams & CustomNotificationParams} NotificationParams
 */

/**
 * @param {*} message
 * @param {*} [params]
 * @param {NotificationParams} [notificationParams]
 */
const displaySuccessNotification = (message, params, notificationParams) => {
	let component;
	let additionalProps;

	if (isString(message)) {
		component = DefaultNotification;

		additionalProps = {
			type: "success",
			message,
		};
	} else {
		component = message;

		additionalProps = params;
	}

	svc.uuiNotifications.show(
		(props) => {
			return component({
				...props,
				...additionalProps,
			});
		},
		{
			position: "bot-left",
			duration: TIMEOUT,
			type: NOTIFICATION_TYPES.SUCCESS,
			...notificationParams,
		},
	)
		.catch((err) => {
			console.error(err);
		});
};

/**
 * @typedef {Object} DisplayInfoNotificationParams
 * @property {string | SnackbarCard} message
 * @property {Partial<SnackbarCardProps>} [additionalComponentProps]
 * @property {NotificationParams} [notificationParams]
 */

/**
 * @param {DisplayInfoNotificationParams} params
 * @returns {void}
 */
const displayInfoNotification = ({
	message,
	additionalComponentProps,
	notificationParams,
}) => {
	/** @type {import("react").FC<DefaultNotificationProps> | SnackbarCard} */
	let component;
	/** @type {AdditionalProps | Partial<SnackbarCardProps>} */
	let additionalProps;

	if (isString(message)) {
		component = DefaultNotification;

		additionalProps = {
			type: "info",
			message,
		};
	} else {
		component = message;

		additionalProps = additionalComponentProps;
	}

	svc.uuiNotifications.show(
		(props) => {
			return component({
				...props,
				...additionalProps,
			});
		},
		{
			position: "bot-left",
			duration: TIMEOUT,
			type: NOTIFICATION_TYPES.INFO,
			...notificationParams,
		},
	)
		.catch((err) => {
			console.error(err);
		});
};

const displayErrorNotification = (message, tag) => {
	runWhenSameTagNotExists(tag, () => {
		svc.uuiNotifications.show(
			(props) => {
				return (
					<DefaultNotification
						type="danger"
						message={message}
						{...props}
					/>
				);
			},
			{
				position: "bot-left",
				duration: "forever",
				type: NOTIFICATION_TYPES.ERROR,
				tag,
			},
		)
			.catch(noop);
	});
};

const displayWarningNotification = (message, tag, params = {}) => {
	runWhenSameTagNotExists(tag, () => {
		let component;
		let additionalProps;

		if (isString(message)) {
			component = DefaultNotification;

			additionalProps = {
				type: "warning",
				message,
			};
		} else {
			component = message;

			additionalProps = params;
		}

		svc.uuiNotifications.show(
			(props) => {
				return component({
					...props,
					...additionalProps,
				});
			},
			{
				position: "bot-left",
				duration: "forever",
				type: NOTIFICATION_TYPES.WARNING,
				tag,
			},
		)
			.catch(noop);
	});
};

export {
	removeNotificationByTag,
	displayErrorNotification,
	displaySuccessNotification,
	displayInfoNotification,
	displayWarningNotification,
};
