/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import cloneDeep from "lodash/cloneDeep";
import constant from "lodash/constant";
import {
	Component,
	type FC,
	type ReactNode,
} from "react";

import {
	svc,
} from "constants/services";
import {
	type FilterIconButtonProps,
} from "types/filter";
import {
	getDataFromLocalStorage,
} from "utils/get-data-from-local-storage";

import {
	FilterIconButton,
} from "./filter-icon-button";
import {
	type FilterPanelOwnProps,
	FiltersPanel,
} from "./filter-panel/filters-panel";

interface FilterWrapperProps<FilterValues> extends FilterPanelOwnProps<FilterValues> {
	filterChanged: (filterValues: FilterValues) => boolean;
	renderButton: FC<FilterIconButtonProps>;
}

class FilterWrapper<FilterValues> extends Component<FilterWrapperProps<FilterValues>> {
	private readonly toggleFilter = (): void => {
		const {
			// Destructuring to avoid passing unnecessary props to `FiltersPanel`.
			filterChanged,
			renderButton,

			...otherProps
		} = this.props;

		svc.uuiModals.show<undefined>((modalWindowProps) => {
			return (
				<FiltersPanel
					{...modalWindowProps}
					{...otherProps}
				/>
			);
		})
			.catch(constant(null));
	};

	private readonly getFilterValuesFromLocalStorage = (): FilterValues => {
		const {
			localStorageKey,
			filterValuesDefault,
		} = this.props;

		return getDataFromLocalStorage({
			key: localStorageKey,
			defaultValue: cloneDeep(filterValuesDefault),
		});
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	public render(): ReactNode {
		const {
			getFilterValuesFromLocalStorage = this.getFilterValuesFromLocalStorage,
			filterChanged,
			renderButton: Button = FilterIconButton,
		} = this.props;
		const filterValues = getFilterValuesFromLocalStorage();
		const isFilterApplied = filterChanged(filterValues);

		return (
			<Button
				onClick={this.toggleFilter}
				customFilterApplied={isFilterApplied}
			/>
		);
	}
}

export {
	FilterWrapper,
};
