/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
	FlexRow,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	MAX_STRUCTURE_LEVEL,
} from "../../consts";
import OpenerIndicator from "../opener-indicator/OpenerIndicator";

import styles from "./StreamContainer.module.css";

/**
 * @typedef {Object} StreamProps
 * @property {boolean} isDisabled
 */

/**
 * @extends {Component<StreamProps, Object>}
 */
class Stream extends Component {
	state = {
		opened: false,
	};

	onTitleClick = () => {
		this.setState((state) => {
			return {
				opened: !state.opened,
			};
		});
	};

	render() {
		const {
			data,
			stream,
			selectedValues,
			checkStream,
			indeterminate,
			isDisabled,
		} = this.props;

		const streamInfo = (
			<div
				className={styles["stream-info"]}
				title={data[stream].name}
				key={stream}
			>
				<Checkbox
					value={
						Boolean(
							selectedValues
							&& selectedValues[stream],
						)
					}
					onValueChange={() => {
						return checkStream(stream);
					}}
					indeterminate={
						Boolean(
							indeterminate
							&& indeterminate[stream],
						)
					}
					isDisabled={isDisabled}
				/>

				<div
					className={
						classNames(
							styles[`level${data[stream].level}`],
							styles["overlong-name"],
						)
					}
				>
					{data[stream].name}
				</div>
			</div>
		);

		return (
			<>
				<FlexRow
					padding="6"
					onClick={this.onTitleClick}
					cx={
						classNames(
							styles[`level${data[stream].level}`],
							styles.stream,
						)
					}
				>
					{
						(
							data[stream].children
							&& data[stream].level < MAX_STRUCTURE_LEVEL
						)
							? (
								<OpenerIndicator
									open={this.state.opened}
									cx={styles["open-indicator"]}
								>
									{streamInfo}
								</OpenerIndicator>
							)
							: (
								<div className={styles["stream-without-child"]}>
									{streamInfo}
								</div>
							)
					}
				</FlexRow>

				{
					(
						this.state.opened
						&& data[stream].children
						&& data[stream].level < MAX_STRUCTURE_LEVEL
					)
						? data[stream]
							.children
							.sort((id1, id2) => {
								const el1 = data[id1];
								const el2 = data[id2];

								if (el1.name === "Root positions") {
									return -1;
								}

								if (el2.name === "Root positions") {
									return 1;
								}

								return el1.name.trim().localeCompare(el2.name.trim());
							})
							.map((childStream) => {
								return (
									<Stream
										key={childStream}
										{...this.props}
										stream={childStream}
									/>
								);
							})
						: null
				}
			</>
		);
	}
}

export default Stream;
