/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as InfoIcon,
} from "@epam/assets/icons/common/notification-info-outline-18.svg";
import {
	IconContainer,
	Switch,
	Tooltip,
} from "@epam/loveship";
import {
	bool,
	func,
	node,
	string,
} from "prop-types";

import styles from "./switcher-with-tooltip.module.css";

const SwitcherWithTooltip = ({
	value,
	label,
	onValueChange,
	tooltip,
}) => {
	if (
		!label
		&& !onValueChange
	) {
		return (
			<div>
				no switcher
			</div>
		);
	}

	return (
		<>
			<Switch
				label={label}
				value={value}
				onValueChange={onValueChange}
			/>
			{
				tooltip
					? (
						<Tooltip
							content={tooltip}
							placement="bottom"
							color="white"
						>
							<IconContainer
								icon={InfoIcon}
								cx={styles.infoIcon}
							/>
						</Tooltip>
					)
					: null
			}
		</>
	);
};

SwitcherWithTooltip.propTypes = {
	label: string,
	value: bool,
	onValueChange: func,
	tooltip: node,
};

export {
	SwitcherWithTooltip,
};
