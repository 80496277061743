/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const TIMEOUT = 10;

/*
	Used for notifications, that should be displayed when moving from one page to
	another.
	It is necessary, because usually when a page component is unmounted,
	all the notifications are cleared without conditions, which is not always
	needed.
*/
const TRANSITIONAL_NOTIFICATION_TAG = "TRANSITIONAL_NOTIFICATION";

const NOTIFICATION_TYPES = {
	ERROR: "ERROR",
	SUCCESS: "SUCCESS",
	WARNING: "WARNING",
	INFO: "INFO",
};

export {
	TRANSITIONAL_NOTIFICATION_TAG,
	TIMEOUT,
	NOTIFICATION_TYPES,
};
