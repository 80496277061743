/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	DatePicker,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	UUI_PICKER_INPUT_WITH_DASHES_FORMAT,
} from "models/dates-and-time/constants";
import {
	formatDefaultDate,
} from "models/dates-and-time/utils/common";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./ChooseDatePeriod.module.css";

class ChooseDate extends Component {
	state = {
		date: formatDefaultDate(this.props.date),
	};

	close = () => {
		this.props.success();
	};

	lockHandler = () => {
		this.props.success({
			date: formatDefaultDate(this.state.date),
		});
	};

	setDate = (date) => {
		this.setState({
			date,
		});
	};

	render() {
		const {
			checkDate,
			header,
			subheader,
			actionTitle,
			tips,
		} = this.props;
		const {
			date,
		} = this.state;
		const errors = checkDate(date);

		return (
			<ModalBlocker
				{...this.props}
			>
				<ModalWindow width="600">
					<ModalHeader
						rawProps={{
							"data-name": toDataAttribute("Modal window header"),
						}}
						onClose={this.close}
					>
						<span className={styles.header}>
							{header}
						</span>
						{
							subheader
								? (
									<p className={styles.subheader}>
										{subheader}
									</p>
								)
								: null
						}
					</ModalHeader>
					<div className={styles.body}>
						<div className={styles.dates}>
							<span className={styles["date-header"]}>
								till date:
							</span>
							<div
								className={
									classNames(
										errors.toDateHasError && styles.wrongDate,
										styles.date,
									)
								}
							>
								<DatePicker
									value={date}
									onValueChange={this.setDate}
									isInvalid={
										errors
											? errors.length
											: null
									}
									format={UUI_PICKER_INPUT_WITH_DASHES_FORMAT}
								/>
								{
									errors.map((error, key) => {
										return (
											<p
												key={key}
												className={
													classNames(
														styles.tips,
														styles.error,
													)
												}
											>
												{error}
											</p>
										);
									})
								}
							</div>
						</div>

						{
							(
								tips
								&& tips.length
							)
								? tips.map((tip, key) => {
									return (
										<p
											key={key}
											className={styles.tips}
										>
											{tip}
										</p>
									);
								})
								: null
						}
						<p className={styles.tips}>
							Employees won’t be able to report time for a project before the defined lock date
						</p>
					</div>
					<ModalFooter
						rawProps={{
							"data-name": toDataAttribute("Modal window footer"),
						}}
					>
						<div className={styles["left-auto"]}/>
						<Button
							cx={
								classNames(
									styles.cancel,
									styles.button,
									styles.footer,
								)
							}
							onClick={this.close}
							caption="Cancel"
							color="white"
						/>
						<Button
							caption={actionTitle}
							isDisabled={
								errors
									? errors.length
									: null
							}
							onClick={this.lockHandler}
							color="grass"
						/>
					</ModalFooter>
				</ModalWindow>
			</ModalBlocker>
		);
	}
}

export default ChooseDate;
