/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Dropdown,
	DropdownContainer,
	FlexRow,
	IconButton,
} from "@epam/loveship";
import isFunction from "lodash/isFunction";
import {
	useState,
} from "react";
import {
	connect,
} from "react-redux";

import {
	ReactComponent as MarkupIcon,
} from "icons/markup-20.svg";

import {
	MARKUP_ACTION_TYPE,
	MARKUP_APPLIED_LEVEL,
} from "../../consts";
import {
	showMarkupActionsModal,
} from "../markups-actions/showMarkupActionsModal";

import styles from "./CommonMarkupDropdown.module.css";

const getTitle = (value) => {
	return value[0] + value.slice(1).toLocaleLowerCase();
};

const MarkupDropdown = ({
	isHideTitle,
	markupStatusesActions,
	markupActionLevel,
	markupStatuses,
	id,
	filterDates,
	worklogDate,
	actionCallback,
	isGrayBtn,
	abort,
}) => {
	const [
		isOpen,
		setIsOpen,
	] = useState(false);

	const onMarkupAction = (action) => {
		const avalableStatuses = markupStatusesActions[action]
			.map((status) => {
				return {
					...status,
					...markupStatuses[status.statusId],
				};
			});

		showMarkupActionsModal({
			id,
			action,
			markupLevel: markupActionLevel,
			statuses: avalableStatuses,
			callback: actionCallback,
			filterDates,
			worklogDate,
		});
	};

	return (
		<Dropdown
			onValueChange={(value) => {
				setIsOpen(value);

				if (
					!value
					&& abort
					&& isFunction(abort)
				) {
					abort();
				}
			}}
			value={isOpen}
			placement="bottom"
			renderTarget={(props) => {
				return (
					<IconButton
						{...props}
						cx={
							isGrayBtn
								? styles["gray-icon"]
								: styles.icon
						}
						icon={MarkupIcon}
						color="sky"
						isDropdown={false}
					/>
				);
			}}
			renderBody={() => {
				return (
					<DropdownContainer
						width="auto"
						cx={styles["dropdown-container"]}
					>
						{
							!isHideTitle
								? (
									<FlexRow
										padding="12"
										size="24"
										vPadding="12"
										cx={styles["dropdown-title"]}
									>
										Status
									</FlexRow>
								)
								: null
						}

						{
							Object
								.keys(markupStatusesActions)
								.map((actionType) => {
									return markupStatusesActions[actionType].length
										? (
											<FlexRow
												key={actionType}
												padding="12"
												size="24"
												vPadding="12"
												onClick={() => {
													onMarkupAction(actionType);
												}}
											>
												{
													actionType === MARKUP_ACTION_TYPE.APPLY
														? "Set"
														: getTitle(actionType)
												}
											</FlexRow>
										)
										: null;
								})
						}
					</DropdownContainer>
				);
			}}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	const markupLevel = ownProps.markupActionLevel;
	let markupStatusesActions;

	const getApplayedStatuses = (compareField, markupStatuses) => {
		const applayedStatuses = new Set();
		const applayedStatusesForRemove = [];

		Object
			.values(state.journalData.worklogs)
			.forEach((worklog) => {
				if (worklog[compareField] === ownProps.id) {
					if (
						worklog.markupStatuses
						&& worklog.markupStatuses.length
					) {
						worklog.markupStatuses.forEach((status) => {
							applayedStatuses.add(status.id);
						});
					}
				}
			});

		markupStatuses.forEach((status) => {
			if (applayedStatuses.has(status.statusId)) {
				applayedStatusesForRemove.push(status);
			}
		});

		return applayedStatusesForRemove;
	};

	switch (markupLevel) {
		case MARKUP_APPLIED_LEVEL.PROJECT: {
			markupStatusesActions = {
				...state.journalData.projects[ownProps.id]
					.markupStatusesActions[MARKUP_APPLIED_LEVEL.PROJECT],
			};

			if (
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
				&& markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].length
			) {
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE] = getApplayedStatuses(
					"projectId",
					[
						...markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE],
					],
				);
			}

			break;
		}

		case MARKUP_APPLIED_LEVEL.PACKAGE: {
			markupStatusesActions = {
				...state
					.journalData
					.projects[
						state
							.journalData
							.tasks[ownProps.id]
							.projectId
					]
					.markupStatusesActions[MARKUP_APPLIED_LEVEL.PACKAGE],
			};

			if (
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
				&& markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].length
			) {
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE] = getApplayedStatuses(
					"taskId",
					[
						...markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE],
					],
				);
			}

			break;
		}

		case MARKUP_APPLIED_LEVEL.ACTIVITY: {
			markupStatusesActions = {
				...state
					.journalData
					.projects[
						state
							.journalData
							.activities[ownProps.id]
							.projectId
					]
					.markupStatusesActions[MARKUP_APPLIED_LEVEL.ACTIVITY],
			};

			if (
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
				&& markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].length
			) {
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE] = getApplayedStatuses(
					"activityId",
					[
						...markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE],
					],
				);
			}

			break;
		}

		case MARKUP_APPLIED_LEVEL.WORKLOG: {
			let worklogsIds;
			let id;

			if (!Array.isArray(ownProps.id)) {
				id = ownProps.id;

				worklogsIds = [
					ownProps.id,
				];
			} else {
				id = ownProps.id.length
					? ownProps.id[0]
					: null;

				worklogsIds = ownProps.id;
			}

			markupStatusesActions = id
				? {
					...state
						.journalData
						.projects[
							state
								.journalData
								.worklogs[id]
								.projectId
						]
						.markupStatusesActions[MARKUP_APPLIED_LEVEL.WORKLOG],
				}
				: {};

			if (
				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
				&& markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].length
			) {
				const applayedStatuses = new Set();
				const applayedStatusesForRemove = [];

				worklogsIds.forEach((worklogId) => {
					const worklog = state.journalData.worklogs[worklogId];

					if (
						worklog.markupStatuses
						&& worklog.markupStatuses.length
					) {
						worklog.markupStatuses.forEach((status) => {
							applayedStatuses.add(status.id);
						});
					}
				});

				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].forEach((status) => {
					if (applayedStatuses.has(status.statusId)) {
						applayedStatusesForRemove.push(status);
					}
				});

				markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE] = (
					applayedStatusesForRemove
				);
			}

			break;
		}

		default: {
			break;
		}
	}

	const markupStatuses = state.journalData.markupStatuses;

	return {
		markupStatusesActions,
		markupStatuses,
	};
};

export default connect(mapStateToProps)(MarkupDropdown);
