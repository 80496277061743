/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	PickerInput,
} from "@epam/loveship";
import {
	useArrayDataSource,
} from "@epam/uui-core";

import {
	DataPickerNotFoundPlaceholder,
} from "pages/components/data-picker-not-found-placeholder/data-picker-not-found-placeholder";

/**
 * @typedef {import("react").ComponentProps<typeof PickerInput>} PickerInputWrapperProps
 */

/**
 * @typedef {import("@epam/uui-core").ArrayDataSourceProps} DataSourceProps
 */

/** @type {import("react").FC<PickerInputWrapperProps & DataSourceProps>} */
const PickerInputWrapper = ({
	items,
	getId,
	selectionMode = "single",
	...otherProps
}) => {
	const dataSource = useArrayDataSource(
		{
			items,
			getId,
		},
		[],
	);

	return (
		<PickerInput
			{...otherProps}
			dataSource={dataSource}
			selectionMode={selectionMode}
			renderNotFound={DataPickerNotFoundPlaceholder}
		/>
	);
};

export default PickerInputWrapper;
