/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	IconContainer,
	Tooltip,
} from "@epam/loveship";

import {
	ReactComponent as nullStatus,
} from "icons/default-billability-status-24.svg";
import FulltimeIcon from "icons/full-time-16.png";
import NotActiveIcon from "icons/not-active-16.png";
import PartTimeIcon from "icons/part-time-16.png";

import {
	EMPLOYMENT_STATUSES,
} from "../../../environment/consts";

import "./EmployeeBillableStatus.css";

const FulltimeIconImage = (
	<img
		src={FulltimeIcon}
		width={16}
		height={16}
		className="icon"
		alt="overtime"
	/>
);

const NotActiveIconImage = (
	<img
		src={NotActiveIcon}
		width={16}
		height={16}
		className="icon"
		alt="overtime"
	/>
);

const PartTimeIconImage = (
	<img
		src={PartTimeIcon}
		width={16}
		height={16}
		className="icon"
		alt="overtime"
	/>
);

const EMPLOYEE_STATUS_TYPES = [
	{
		id: 1,
		icon: FulltimeIconImage,
	},
	{
		id: 2,
		icon: FulltimeIconImage,
	},
	{
		id: 3,
		icon: PartTimeIconImage,
	},
	{
		id: 4,
		icon: NotActiveIconImage,
	},
	{
		id: 5,
		icon: NotActiveIconImage,
	},
	{
		id: 7,
		icon: NotActiveIconImage,
	},
	{
		id: 8,
		icon: FulltimeIconImage,
	},
];

const EmployeeBillableStatus = ({
	employmentStatusId,
}) => {
	const employeeStatusType = EMPLOYEE_STATUS_TYPES.find((el) => {
		return el.id === Number(employmentStatusId);
	});
	const employeeStatus = EMPLOYMENT_STATUSES.find((el) => {
		return el.id === Number(employmentStatusId);
	});

	return (
		<div
			className={
				employeeStatusType
					? "employee-billable-info"
					: "employee-billable-info svgIcon"
			}
		>
			<Tooltip
				color="white"
				placement="bottom"
				content={
					employeeStatus
						? employeeStatus.name
						: "Not active"
				}
			>
				{
					employeeStatusType
						? employeeStatusType.icon
						: <IconContainer icon={nullStatus}/>
				}
			</Tooltip>
		</div>
	);
};

export default EmployeeBillableStatus;
