/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CalendarIcon,
} from "@epam/assets/icons/common/action-calendar-18.svg";
import {
	ReactComponent as CloseIcon,
} from "@epam/assets/icons/common/navigation-close_bold-18.svg";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	PERIODS,
} from "../../../environment/journal_environment";
import DatePicker from "../date-picker/DatePicker";

import "./DateInput.css";

class DateInput extends Component {
	state = {
		datePickerOpened: false,
	};

	componentDidMount() {
		document.addEventListener("click", this.checkIfClickedOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.checkIfClickedOutside, true);
	}

	checkIfClickedOutside = (event) => {
		if (
			this.dateInput
			&& !this.dateInput.contains(event.target)
		) {
			this.setState(() => {
				return {
					datePickerOpened: false,
				};
			});
		}
	};

	toggleDatePicker = () => {
		const {
			disabled,
		} = this.props;

		if (!disabled) {
			this.setState(({
				datePickerOpened,
			}) => {
				return {
					datePickerOpened: !datePickerOpened,
				};
			});
		}
	};

	setCalendar = (date) => {
		this.setState(() => {
			return {
				datePickerOpened: false,
			};
		});

		this.props.setDate(date);
	};

	clearDate = () => {
		const {
			disabled,
		} = this.props;

		if (!disabled) {
			this.props.setDate(null);
		}
	};

	render() {
		const {
			className,
			inputClassname,
			canClearDate = false,
		} = this.props;

		return (
			<time
				className={
					classNames(
						className,
						"date-input",
					)
				}
				ref={(ref) => {
					this.dateInput = ref;
				}}
			>
				<div
					className={
						classNames(
							inputClassname,
							"date-input-block",
						)
					}
					onClick={this.toggleDatePicker}
				>
					<span className="date-value">
						{this.props.date}
					</span>
					<CalendarIcon
						className="calendarIcon"
					/>
				</div>
				{
					canClearDate
						? (
							<div
								className="clear-date-block"
								onClick={this.clearDate}
							>
								<CloseIcon
									className="closeIcon"
								/>
							</div>
						)
						: null
				}
				{
					this.state.datePickerOpened
						? (
							<DatePicker
								date={this.props.date}
								period={PERIODS.DAY}
								open={this.state.datePickerOpened}
								setCalendar={this.setCalendar}
							/>
						)
						: null
				}
			</time>
		);
	}
}

export default DateInput;
